import { ApiRequest, shiftApi } from '@/api';
import i18n from '@/i18n';
import { dateTimeFormats } from '@/lang/dateTimeFormats';
import { fullName } from '@/lib/employee/employeeFunctions';
import { startsInPeriod } from '@/lib/filters/dateFilters';
import { belongsToEmployee } from '@/lib/filters/employeeFilters';
import { getFilterValues } from '@/lib/filters/filterHelpers';
import { userCan } from '@/lib/permission/userCan';
import { ScheduleV2FilterType } from '@/lib/schedule-v2/ScheduleV2Filter';
import { shiftHasConflict } from '@/lib/schedule-v2/shiftV2Functions';
import { isOpenAndUnassigned } from '@/lib/schedule-v2/shiftV2Status';
import spacetime from 'spacetime';
import { Spacetime } from 'spacetime/types/types.d';
import {
  Employee,
  EmployeeStatusEnum,
  Schedule,
  Shift,
  ShiftAreaSession,
} from '../../../api/v1';

// Various filters that can be applied to entities like Employees, Shifts, or Schedule Events
export const assignedFilter =
  (showUnassigned: boolean, assignedEmployeeIds: number[]) =>
  (employee: Employee) =>
    showUnassigned || assignedEmployeeIds.includes(employee.id);

export const jobRoleFilter =
  (selectedIds: Array<number | null>) =>
  ({ jobRoleId }: { jobRoleId: number }) =>
    selectedIds.length === 0 ||
    selectedIds.includes(null) ||
    selectedIds.includes(jobRoleId);
export const scheduleFilter =
  (activeScheduleIds: number[], selectedId: number | null) =>
  ({ scheduleId }: { scheduleId: number }) =>
    [null, ...activeScheduleIds].includes(scheduleId) &&
    [null, scheduleId].includes(selectedId);

export const scheduleFilterMultiple =
  (activeScheduleIds: number[], selectedId: number | null) =>
  ({ scheduleIds }: { scheduleIds: number[] }) =>
    activeScheduleIds.some((id) => scheduleIds.includes(id)) &&
    [null, ...scheduleIds].includes(selectedId);

export const employeeScheduleFilter =
  (selectedId: number | null) =>
  ({ scheduleIds }: Employee): boolean => {
    return [null, ...scheduleIds].includes(selectedId);
  };

export const scheduleEmployeeStatusFilter =
  (includePending: boolean = true, shifts: Shift[] = []) =>
  (employee: Employee): boolean => {
    // If the employee has a shift we want to include them regardless of their status
    if (shifts.find((s) => s.employeeId === employee.id)) return true;

    const activeStatus = [EmployeeStatusEnum.Active];
    if (includePending) activeStatus.push(EmployeeStatusEnum.Pending);
    return activeStatus.includes(employee.status);
  };

/**
 * Filters employees for the schedule based on their join date.
 * If it's the current logged in employee, they have shifts or no join date then display anyway.
 * @param dateRange
 * @param loggedInEmployee
 * @param shifts
 * @param timezone
 */
export const scheduleEmployeeJoinDateFilter =
  (
    dateRange: { startDate: Date; endDate: Date },
    loggedInEmployee: Employee,
    shifts: Shift[],
    timezone: string,
  ) =>
  (employee: Employee) =>
    loggedInEmployee.id === employee.id ||
    shifts.some(
      (s) =>
        belongsToEmployee(employee.id)(s) &&
        startsInPeriod(dateRange.startDate, dateRange.endDate)(s),
    ) ||
    !employee.joinDate ||
    employee.joinDate.toDate(timezone) < dateRange.endDate;

export const tagFilter =
  (selectedIds: number[]) =>
  ({ tagIds }: { tagIds: number[] }) =>
    selectedIds.length === 0 || selectedIds.some((id) => tagIds.includes(id));
export const publishedFilter = (showUnpublished: boolean) => (shift: Shift) =>
  showUnpublished || shift.publishedAt;

// Not already published, is assigned, same schedule, starts in future and has no conflicts
export const publishShiftFilter =
  (now: Date, scheduleId: Number) =>
  (shift: Shift): boolean =>
    !shift.publishedAt &&
    (shift.open || shift.employeeId) &&
    shift.scheduleId === scheduleId &&
    shift.startsAt > now &&
    (isOpenAndUnassigned(shift) || !shiftHasConflict(shift));

export const locationFilter = (locationId: number[]) => (shift: Shift) =>
  !locationId.length || locationId.includes(shift.locationId);

export const employeeShiftFilter = (employeeIds: number[]) => (shift: Shift) =>
  !employeeIds.length || employeeIds.includes(shift.employeeId);

export const shiftFilter =
  (activeScheduleIds: number[], filter: ScheduleV2FilterType) =>
  (shift: Shift): boolean =>
    [shift]
      .filter(jobRoleFilter(getFilterValues(filter.jobRoleIds)))
      .filter(scheduleFilter(activeScheduleIds, filter.scheduleId))
      .filter(tagFilter(getFilterValues(filter.tagIds)))
      .filter(locationFilter(getFilterValues(filter.locationId))).length > 0;

export const shiftAreaFilter =
  (activeScheduleIds: number[], filter: ScheduleV2FilterType) =>
  (shift: Shift): boolean =>
    [shift]
      .filter(jobRoleFilter(getFilterValues(filter.jobRoleIds)))
      .filter(scheduleFilter(activeScheduleIds, filter.scheduleId))
      .filter(locationFilter(getFilterValues(filter.locationId)))
      .filter(employeeShiftFilter(getFilterValues(filter.employeeIds))).length >
    0;

export const shiftIsIncludedInFilter = (
  shift: Shift,
  schedules: Schedule[],
  filter: ScheduleV2FilterType,
): boolean => {
  const scheduleIds = schedules.map((schedule) => schedule.id);
  return shiftFilter(scheduleIds, filter)(shift);
};

// Sort functions
export const employeeNameSort = (employeeA: Employee, employeeB: Employee) =>
  fullName(employeeA).localeCompare(fullName(employeeB));

export const shiftsCanBeGrouped = <T extends Partial<Shift>>(
  a: T,
  b: T,
): boolean => {
  const getHash = ({
    companyId,
    locationId,
    scheduleId,
    employeeId,
    jobRoleId,
    open,
    requiresApproval,
    startsAt,
    endsAt,
    showEndTime,
    showJobRole,
    scheduledBreaks,
    shiftAreaSessions,
    notes,
    tagIds,
    publishedAt,
  }: T) =>
    JSON.stringify({
      companyId,
      locationId,
      scheduleId,
      employeeId,
      jobRoleId,
      open,
      requiresApproval,
      startsAt,
      endsAt,
      showEndTime,
      showJobRole,
      // Scheduled breaks need formatting due to differing id and shiftId
      scheduledBreaks: scheduledBreaks.map((b) => ({
        startsAt: b.startsAt,
        durationInMinutes: b.durationInMinutes,
        paid: b.paid,
      })),
      shiftAreaSessions: shiftAreaSessions?.map((a) => ({
        startTime: a.startTime,
        endTime: a.endTime,
        shiftAreaId: a.shiftAreaId,
      })),
      notes,
      tagIds,
      published: !!publishedAt,
    });

  return getHash(a) === getHash(b);
};

/** Get an array of ids matching the provided shift */
export const matchingShiftIds = (shift: Shift, shifts: Shift[]): number[] =>
  shifts
    .filter((s) => s.id !== shift.id && shiftsCanBeGrouped(s, shift))
    .map((s) => s.id);
/** Checks shift does not have matching previous shifts in an array */
export const doesNotHaveMatchingPreviousShifts = (
  shift: Shift,
  shifts: Shift[],
): boolean =>
  shifts.filter((s) => s.id < shift.id && shiftsCanBeGrouped(s, shift))
    .length === 0;

/** Get a series of date objects that represent the 7 days of the week */
export const getWeekDateHeaders = (
  date: Date,
  timezone: string,
  dayFormat: string,
  dateFormat: string,
): { dayName: string; dateString: string; start: Date; end: Date }[] => {
  const weekStart = spacetime(date, timezone).startOf('day');
  const weekEnd = weekStart.add(7, 'day');
  const dates = weekStart.every(weekEnd, 'day', 1);

  return dates.map((start: Spacetime) => {
    // If crossover DST this becomes 01:00 so set back to start of day
    start = start.startOf('day');
    const end = start.add(1, 'day').startOf('day');
    // @todo swap to use $d() once this spacetime issue is resolved:
    // https://github.com/spencermountain/spacetime/issues/343
    return {
      dayName: start.format(dayFormat),
      dateString: start.format(dateFormat),
      start: start.toNativeDate(),
      end: end.toNativeDate(),
    };
  });
};

export const getShiftsForAcknowledgement = async (
  employeeId: number,
): Promise<Array<Shift>> => {
  const { data } = await new ApiRequest(shiftApi, 'listShifts')
    .where('employeeId', 'eq', employeeId)
    .where('startsAt', 'gte', new Date())
    .whereNull('acknowledgedAt')
    .whereNotNull('publishedAt')
    .orderBy('startsAt')
    .fetchAll();

  return data;
};

export const presentShiftTimes = ({
  startsAt,
  endsAt,
  showEndTime,
}: {
  startsAt: Date;
  endsAt: Date;
  showEndTime: Boolean;
}): string => {
  const start = i18n.d(startsAt, dateTimeFormats.hourMinute);
  const end = i18n.d(endsAt, dateTimeFormats.hourMinute);

  return start + (showEndTime || userCan.manageShifts() ? ` - ${end}` : '');
};

/**
 * This is almost identical to our normal presentShiftTimes function, except that we assume the print-out is going
 * to be viewed by non-managers, and so here we always obey the shift's own setting for whether to show end times.
 */
export const presentShiftTimesForPrinting = (
  { startsAt, endsAt, showEndTime }: Shift,
  ignoreShowShiftEndTimeField: boolean,
): string => {
  const start = i18n.d(startsAt, dateTimeFormats.hourMinute);
  const end =
    showEndTime || ignoreShowShiftEndTimeField
      ? `- ${i18n.d(endsAt, dateTimeFormats.hourMinute)}`
      : '→';
  return `${start} ${end}`;
};

export const isNightShift = (shift: Shift, timezone: string): boolean =>
  !spacetime(shift.startsAt, timezone).isSame(
    spacetime(shift.endsAt, timezone),
    'day',
  );
export const isNightAreaSession = (
  session: ShiftAreaSession,
  timezone: string,
): boolean =>
  !spacetime(session.startTime, timezone).isSame(
    spacetime(session.endTime, timezone),
    'day',
  );
