/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BulkUpdateShiftsDataUpdateData } from './BulkUpdateShiftsDataUpdateData';
import {
    BulkUpdateShiftsDataUpdateDataFromJSON,
    BulkUpdateShiftsDataUpdateDataFromJSONTyped,
    BulkUpdateShiftsDataUpdateDataToJSON,
    BulkUpdateShiftsDataUpdateDataToJSONTyped,
} from './BulkUpdateShiftsDataUpdateData';

/**
 * 
 * @export
 * @interface BulkUpdateShiftsData
 */
export interface BulkUpdateShiftsData {
    /**
     * The ids of models to update
     * @type {Array<number>}
     * @memberof BulkUpdateShiftsData
     */
    ids: Array<number>;
    /**
     * 
     * @type {BulkUpdateShiftsDataUpdateData}
     * @memberof BulkUpdateShiftsData
     */
    updateData: BulkUpdateShiftsDataUpdateData;
}

/**
 * Check if a given object implements the BulkUpdateShiftsData interface.
 */
export function instanceOfBulkUpdateShiftsData(value: object): value is BulkUpdateShiftsData {
    if (!('ids' in value) || value['ids'] === undefined) return false;
    if (!('updateData' in value) || value['updateData'] === undefined) return false;
    return true;
}

export function BulkUpdateShiftsDataFromJSON(json: any): BulkUpdateShiftsData {
    return BulkUpdateShiftsDataFromJSONTyped(json, false);
}

export function BulkUpdateShiftsDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkUpdateShiftsData {
    if (json == null) {
        return json;
    }
    return {
        
        'ids': json['ids'],
        'updateData': BulkUpdateShiftsDataUpdateDataFromJSON(json['updateData']),
    };
}

  export function BulkUpdateShiftsDataToJSON(json: any): BulkUpdateShiftsData {
      return BulkUpdateShiftsDataToJSONTyped(json, false);
  }

  export function BulkUpdateShiftsDataToJSONTyped(value?: BulkUpdateShiftsData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'ids': value['ids'],
        'updateData': BulkUpdateShiftsDataUpdateDataToJSON(value['updateData']),
    };
}

