/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateLeaveTypeData
 */
export interface UpdateLeaveTypeData {
    /**
     * 
     * @type {number}
     * @memberof UpdateLeaveTypeData
     */
    deductionLeaveTypeId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateLeaveTypeData
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateLeaveTypeData
     */
    status?: UpdateLeaveTypeDataStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateLeaveTypeData
     */
    colour?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateLeaveTypeData
     */
    paid?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateLeaveTypeData
     */
    payCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateLeaveTypeData
     */
    displayIcon?: UpdateLeaveTypeDataDisplayIconEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateLeaveTypeData
     */
    leaveAllowanceType?: UpdateLeaveTypeDataLeaveAllowanceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof UpdateLeaveTypeData
     */
    leaveAllowanceUnit?: UpdateLeaveTypeDataLeaveAllowanceUnitEnum;
    /**
     * Leave allowance for this leave type when using the 'Fixed' allowance type.
     * @type {number}
     * @memberof UpdateLeaveTypeData
     */
    leaveAllowance?: number | null;
    /**
     * What percentage of worked hours an employee accrues for this leave type. A rate of 100 means leave is accrued at the same rate as hours worked.
     * @type {number}
     * @memberof UpdateLeaveTypeData
     */
    accrualRatePerHour?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateLeaveTypeData
     */
    accrualLimitDaysPerPeriod?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateLeaveTypeData
     */
    accrualAllowedOnOvertimeHours?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum UpdateLeaveTypeDataStatusEnum {
    Active = 'Active',
    Archived = 'Archived',
    Deleted = 'Deleted'
}
/**
* @export
* @enum {string}
*/
export enum UpdateLeaveTypeDataDisplayIconEnum {
    SolidFaBaby = 'fa-solid fa-baby',
    SolidFaBabyCarriage = 'fa-solid fa-baby-carriage',
    SolidFaBasketShoppingSimple = 'fa-solid fa-basket-shopping-simple',
    SolidFaBed = 'fa-solid fa-bed',
    SolidFaBriefcase = 'fa-solid fa-briefcase',
    SolidFaCakeCandles = 'fa-solid fa-cake-candles',
    SolidFaCar = 'fa-solid fa-car',
    SolidFaCarBus = 'fa-solid fa-car-bus',
    SolidFaClock = 'fa-solid fa-clock',
    SolidFaEarthEurope = 'fa-solid fa-earth-europe',
    SolidFaFaceFrownSlight = 'fa-solid fa-face-frown-slight',
    SolidFaFaceThermometer = 'fa-solid fa-face-thermometer',
    SharpFaSolidFaGavel = 'fa-sharp fa-solid fa-gavel',
    SolidFaGift = 'fa-solid fa-gift',
    SolidFaGraduationCap = 'fa-solid fa-graduation-cap',
    SolidFaHandsPraying = 'fa-solid fa-hands-praying',
    SolidFaHandHoldingHeart = 'fa-solid fa-hand-holding-heart',
    SolidFaHeadSideGear = 'fa-solid fa-head-side-gear',
    SolidFaHouse = 'fa-solid fa-house',
    SolidFaPaw = 'fa-solid fa-paw',
    SharpFaSolidFaPersonBreastfeeding = 'fa-sharp fa-solid fa-person-breastfeeding',
    SharpFaSolidFaPersonPregnant = 'fa-sharp fa-solid fa-person-pregnant',
    SolidFaPlane = 'fa-solid fa-plane',
    SolidFaSnowflake = 'fa-solid fa-snowflake',
    SolidFaSpa = 'fa-solid fa-spa',
    SolidFaSquarePlus = 'fa-solid fa-square-plus',
    SolidFaSuitcaseRolling = 'fa-solid fa-suitcase-rolling',
    SolidFaUmbrellaBeach = 'fa-solid fa-umbrella-beach',
    SolidFaUsers = 'fa-solid fa-users',
    SolidFaVirus = 'fa-solid fa-virus'
}
/**
* @export
* @enum {string}
*/
export enum UpdateLeaveTypeDataLeaveAllowanceTypeEnum {
    Accrued = 'Accrued',
    DeductsFromAnother = 'Deducts From Another',
    Fixed = 'Fixed',
    Unlimited = 'Unlimited'
}
/**
* @export
* @enum {string}
*/
export enum UpdateLeaveTypeDataLeaveAllowanceUnitEnum {
    Days = 'Days',
    Hours = 'Hours'
}


/**
 * Check if a given object implements the UpdateLeaveTypeData interface.
 */
export function instanceOfUpdateLeaveTypeData(value: object): value is UpdateLeaveTypeData {
    return true;
}

export function UpdateLeaveTypeDataFromJSON(json: any): UpdateLeaveTypeData {
    return UpdateLeaveTypeDataFromJSONTyped(json, false);
}

export function UpdateLeaveTypeDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateLeaveTypeData {
    if (json == null) {
        return json;
    }
    return {
        
        'deductionLeaveTypeId': json['deductionLeaveTypeId'] == null ? undefined : json['deductionLeaveTypeId'],
        'name': json['name'] == null ? undefined : json['name'],
        'status': json['status'] == null ? undefined : json['status'],
        'colour': json['colour'] == null ? undefined : json['colour'],
        'paid': json['paid'] == null ? undefined : json['paid'],
        'payCode': json['payCode'] == null ? undefined : json['payCode'],
        'displayIcon': json['displayIcon'] == null ? undefined : json['displayIcon'],
        'leaveAllowanceType': json['leaveAllowanceType'] == null ? undefined : json['leaveAllowanceType'],
        'leaveAllowanceUnit': json['leaveAllowanceUnit'] == null ? undefined : json['leaveAllowanceUnit'],
        'leaveAllowance': json['leaveAllowance'] == null ? undefined : json['leaveAllowance'],
        'accrualRatePerHour': json['accrualRatePerHour'] == null ? undefined : json['accrualRatePerHour'],
        'accrualLimitDaysPerPeriod': json['accrualLimitDaysPerPeriod'] == null ? undefined : json['accrualLimitDaysPerPeriod'],
        'accrualAllowedOnOvertimeHours': json['accrualAllowedOnOvertimeHours'] == null ? undefined : json['accrualAllowedOnOvertimeHours'],
    };
}

  export function UpdateLeaveTypeDataToJSON(json: any): UpdateLeaveTypeData {
      return UpdateLeaveTypeDataToJSONTyped(json, false);
  }

  export function UpdateLeaveTypeDataToJSONTyped(value?: UpdateLeaveTypeData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'deductionLeaveTypeId': value['deductionLeaveTypeId'],
        'name': value['name'],
        'status': value['status'],
        'colour': value['colour'],
        'paid': value['paid'],
        'payCode': value['payCode'],
        'displayIcon': value['displayIcon'],
        'leaveAllowanceType': value['leaveAllowanceType'],
        'leaveAllowanceUnit': value['leaveAllowanceUnit'],
        'leaveAllowance': value['leaveAllowance'],
        'accrualRatePerHour': value['accrualRatePerHour'],
        'accrualLimitDaysPerPeriod': value['accrualLimitDaysPerPeriod'],
        'accrualAllowedOnOvertimeHours': value['accrualAllowedOnOvertimeHours'],
    };
}

