/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Relationships } from './Relationships';
import {
    RelationshipsFromJSON,
    RelationshipsFromJSONTyped,
    RelationshipsToJSON,
    RelationshipsToJSONTyped,
} from './Relationships';

/**
 * 
 * @export
 * @interface BulkUpdateEmployeesDataUpdateData
 */
export interface BulkUpdateEmployeesDataUpdateData {
    /**
     * 
     * @type {number}
     * @memberof BulkUpdateEmployeesDataUpdateData
     */
    workPatternId?: number;
    /**
     * 
     * @type {number}
     * @memberof BulkUpdateEmployeesDataUpdateData
     */
    lineManagerId?: number;
    /**
     * 
     * @type {number}
     * @memberof BulkUpdateEmployeesDataUpdateData
     */
    leaveApproverId?: number;
    /**
     * A leave policy to define when and how the employee can take leave. Note: changing this value to an employee with an existing leave policy may result in leave periods being regenerated when the new policy results in a different start date.
     * @type {number}
     * @memberof BulkUpdateEmployeesDataUpdateData
     */
    leavePolicyId?: number | null;
    /**
     * 
     * @type {Relationships}
     * @memberof BulkUpdateEmployeesDataUpdateData
     */
    locationIds?: Relationships;
    /**
     * 
     * @type {Relationships}
     * @memberof BulkUpdateEmployeesDataUpdateData
     */
    jobRoleIds?: Relationships;
    /**
     * 
     * @type {Relationships}
     * @memberof BulkUpdateEmployeesDataUpdateData
     */
    scheduleIds?: Relationships;
    /**
     * 
     * @type {number}
     * @memberof BulkUpdateEmployeesDataUpdateData
     */
    employeeGroupId?: number | null;
    /**
     * The number of hours this employee would usually work in a week
     * @type {number}
     * @memberof BulkUpdateEmployeesDataUpdateData
     */
    workingHoursPerWeek?: number;
    /**
     * 
     * @type {string}
     * @memberof BulkUpdateEmployeesDataUpdateData
     */
    employmentType?: BulkUpdateEmployeesDataUpdateDataEmploymentTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum BulkUpdateEmployeesDataUpdateDataEmploymentTypeEnum {
    Agency = 'Agency',
    AnnualisedContract = 'Annualised Contract',
    ApprenticeTrainee = 'Apprentice / Trainee',
    Casual = 'Casual',
    FixedTerm = 'Fixed Term',
    FullTime = 'Full Time',
    PartTime = 'Part Time',
    SelfEmployed = 'Self-Employed',
    Temporary = 'Temporary',
    Volunteer = 'Volunteer'
}


/**
 * Check if a given object implements the BulkUpdateEmployeesDataUpdateData interface.
 */
export function instanceOfBulkUpdateEmployeesDataUpdateData(value: object): value is BulkUpdateEmployeesDataUpdateData {
    return true;
}

export function BulkUpdateEmployeesDataUpdateDataFromJSON(json: any): BulkUpdateEmployeesDataUpdateData {
    return BulkUpdateEmployeesDataUpdateDataFromJSONTyped(json, false);
}

export function BulkUpdateEmployeesDataUpdateDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkUpdateEmployeesDataUpdateData {
    if (json == null) {
        return json;
    }
    return {
        
        'workPatternId': json['workPatternId'] == null ? undefined : json['workPatternId'],
        'lineManagerId': json['lineManagerId'] == null ? undefined : json['lineManagerId'],
        'leaveApproverId': json['leaveApproverId'] == null ? undefined : json['leaveApproverId'],
        'leavePolicyId': json['leavePolicyId'] == null ? undefined : json['leavePolicyId'],
        'locationIds': json['locationIds'] == null ? undefined : RelationshipsFromJSON(json['locationIds']),
        'jobRoleIds': json['jobRoleIds'] == null ? undefined : RelationshipsFromJSON(json['jobRoleIds']),
        'scheduleIds': json['scheduleIds'] == null ? undefined : RelationshipsFromJSON(json['scheduleIds']),
        'employeeGroupId': json['employeeGroupId'] == null ? undefined : json['employeeGroupId'],
        'workingHoursPerWeek': json['workingHoursPerWeek'] == null ? undefined : json['workingHoursPerWeek'],
        'employmentType': json['employmentType'] == null ? undefined : json['employmentType'],
    };
}

  export function BulkUpdateEmployeesDataUpdateDataToJSON(json: any): BulkUpdateEmployeesDataUpdateData {
      return BulkUpdateEmployeesDataUpdateDataToJSONTyped(json, false);
  }

  export function BulkUpdateEmployeesDataUpdateDataToJSONTyped(value?: BulkUpdateEmployeesDataUpdateData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'workPatternId': value['workPatternId'],
        'lineManagerId': value['lineManagerId'],
        'leaveApproverId': value['leaveApproverId'],
        'leavePolicyId': value['leavePolicyId'],
        'locationIds': RelationshipsToJSON(value['locationIds']),
        'jobRoleIds': RelationshipsToJSON(value['jobRoleIds']),
        'scheduleIds': RelationshipsToJSON(value['scheduleIds']),
        'employeeGroupId': value['employeeGroupId'],
        'workingHoursPerWeek': value['workingHoursPerWeek'],
        'employmentType': value['employmentType'],
    };
}

