/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClockOutData
 */
export interface ClockOutData {
    /**
     * Which employee should be clocked out. Leave blank to clock yourself out.
     * @type {number}
     * @memberof ClockOutData
     */
    employeeId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClockOutData
     */
    clockingLatitude?: number;
    /**
     * 
     * @type {number}
     * @memberof ClockOutData
     */
    clockingLongitude?: number;
    /**
     * Any notes that the employee wants to add to the timesheet.
     * @type {string}
     * @memberof ClockOutData
     */
    notes?: string | null;
}

/**
 * Check if a given object implements the ClockOutData interface.
 */
export function instanceOfClockOutData(value: object): value is ClockOutData {
    return true;
}

export function ClockOutDataFromJSON(json: any): ClockOutData {
    return ClockOutDataFromJSONTyped(json, false);
}

export function ClockOutDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClockOutData {
    if (json == null) {
        return json;
    }
    return {
        
        'employeeId': json['employeeId'] == null ? undefined : json['employeeId'],
        'clockingLatitude': json['clockingLatitude'] == null ? undefined : json['clockingLatitude'],
        'clockingLongitude': json['clockingLongitude'] == null ? undefined : json['clockingLongitude'],
        'notes': json['notes'] == null ? undefined : json['notes'],
    };
}

  export function ClockOutDataToJSON(json: any): ClockOutData {
      return ClockOutDataToJSONTyped(json, false);
  }

  export function ClockOutDataToJSONTyped(value?: ClockOutData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'employeeId': value['employeeId'],
        'clockingLatitude': value['clockingLatitude'],
        'clockingLongitude': value['clockingLongitude'],
        'notes': value['notes'],
    };
}

