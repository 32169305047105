
import { clockingsApi } from '@/api';
import Button from '@/components/buttons/Button.vue';
import ClockInDialogV2 from '@/components/clockIn/ClockInDialogV2.vue';
import ClockInTimer from '@/components/clockIn/ClockInTimer.vue';
import InputGroup from '@/components/form/InputGroup.vue';
import BubbleIcon from '@/components/interface/BubbleIcon.vue';
import Pill from '@/components/interface/Pill.vue';
import LoadingDot from '@/components/loaders/LoadingDot.vue';
import { dateTimeFormats } from '@/lang/dateTimeFormats';
import {
  employeeCanClockUnscheduledShift,
  getEarliestClockInTimeString,
  isWithinClockInThreshold,
  shiftsForClocking,
  shiftsForPersonalDeviceClocking,
  shiftsWithinNext12Hours,
  timesheetsInProgress,
} from '@/lib/clock-in/clockInFunctions';
import { ClockInDialogTypeEnum } from '@/lib/enum/clock-in/ClockInDialogTypeEnum';
import { Icon, IconType } from '@/lib/enum/Icon';
import { IconSizes } from '@/lib/enum/IconSizes';
import { Now } from '@/lib/helpers/now';
import { clockInFromPersonalDevicesAtLocationFilter } from '@/lib/location/locationFilters';
import { userCan } from '@/lib/permission/userCan';
import {
  realtimeFindEntity,
  realtimeQueryInstantUpdate,
} from '@/lib/realtime/weak/realtimeFunctions';
import { WeakEntityRepositoryUpdateKind } from '@/lib/realtime/weak/WeakEntityRepository';
import { tooEarlyToStartScheduledBreak } from '@/lib/scheduledBreak/scheduledBreakFunctions';
import { Entity } from '@/lib/store/realtimeEntities';
import {
  isScheduledBreakClocked,
  isScheduledBreakInProgress,
} from '@/lib/timesheets/timesheetFunctions';
import store from '@/store';
import { SimpleBubbleIconType } from '@/types/propTypes';
import { sortBy } from '@/util/arrayFunctions';
import { addMinutes, minutesBetweenDates } from '@/util/dateArithmetic';
import { getEntity } from '@/util/entityFunctions';
import { presentMinutes } from '@/util/presenters';
import ShiftSummaryV2 from '@/views/schedule/components/ShiftSummaryV2.vue';
import spacetime from 'spacetime';
import {
  Employee,
  Location,
  ScheduledBreak,
  ScheduleSettingsClockInUnscheduledShiftsEnum,
  Shift,
  TimesheetEntry,
  TimesheetEntryStatusEnum,
} from '../../../api/v1';

type BreakDetailsRowType = {
  minWidth: string;
  header?: string;
  info?: string;
  icon?: SimpleBubbleIconType;
}[];

export default {
  name: 'ClockInWidget',
  components: {
    ClockInDialogV2,
    Button,
    InputGroup,
    ShiftSummaryV2,
    ClockInTimer,
    Pill,
    LoadingDot,
    BubbleIcon,
  },
  data() {
    const {
      loggedInEmployee: { id: employeeId },
      timezone,
    } = store.getters;

    return {
      TimesheetEntryStatusEnum,
      userCan,
      Icon,
      IconSizes,
      dateTimeFormats,

      now: new Now(),
      loading: false,
      clockInDialog: null as {
        show: boolean;
        type: keyof typeof ClockInDialogTypeEnum;
        shift: Shift | null;
        timesheet?: TimesheetEntry | null;
        locationId?: number | null;
        earlyBreakId?: number | null;
        action: Function;
      },
      shiftInProgress: undefined as Shift,
      shiftsForClocking: shiftsForClocking(employeeId, timezone),
      timesheetsInProgress: timesheetsInProgress(employeeId),
      showBreakDetails: false,
    };
  },
  computed: {
    earlyClockInThresholdInMinutes: (): number =>
      store.state.settings.clockingSettings.earlyClockInPeriod,
    enableBreaksWhileClockedIn: (): boolean =>
      store.state.settings.scheduleSettings.enableBreaksWhileClockedIn,
    loggedInEmployee: (): Employee => store.getters.loggedInEmployee,
    timezone: (): string => store.getters.timezone,
    clockInUnscheduledShifts:
      (): ScheduleSettingsClockInUnscheduledShiftsEnum =>
        store.state.settings.scheduleSettings.clockInUnscheduledShifts,
    locations: (): Location[] =>
      store.getters['locations/locationsWithClockInFromPersonalDevicesEnabled'],
    paidBreakName: (): string =>
      store.state.settings.scheduleSettings.paidBreakName,
    unpaidBreakName: (): string =>
      store.state.settings.scheduleSettings.unpaidBreakName,

    initialising(): boolean {
      return (
        this.shiftsForClocking.isLoading || this.timesheetsInProgress.isLoading
      );
    },

    filteredShiftsForClocking(): Shift[] {
      return shiftsWithinNext12Hours(
        this.shiftsForClocking.data,
        this.now.time,
        this.timezone,
      );
    },

    canClockInUnscheduledShifts(): boolean {
      return employeeCanClockUnscheduledShift(
        this.loggedInEmployee.id,
        this.filteredShiftsForClocking,
        this.timezone,
        this.clockInUnscheduledShifts,
        this.activeTimesheet,
      );
    },
    /**
     * Returns either the inprogress shift or the next shift which needs clocking into
     */
    activeShift(): Shift | null {
      if (this.activeTimesheet) {
        return this.shiftInProgress ?? null;
      }
      const shifts = shiftsForPersonalDeviceClocking(
        this.filteredShiftsForClocking,
        this.locations,
      );
      const clockableShifts: Shift[] = sortBy(shifts, 'startsAt');
      return clockableShifts?.[0] ?? null;
    },
    activeTimesheet(): TimesheetEntry | null {
      return this.timesheetsInProgress.data?.[0] ?? null;
    },

    sortedScheduledBreaks(): ScheduledBreak[] {
      const { scheduledBreaks } = this.activeShift || {};
      return scheduledBreaks?.length ? sortBy(scheduledBreaks, 'startsAt') : [];
    },
    scheduledBreakInProgress(): ScheduledBreak | null {
      if (this.activeTimesheet?.status === TimesheetEntryStatusEnum.Break) {
        // Show the current scheduled break in progress
        return this.sortedScheduledBreaks.find((b) =>
          isScheduledBreakInProgress(this.activeTimesheet, b.id),
        );
      }
      // Show the next scheduled break
      return this.activeTimesheet
        ? this.sortedScheduledBreaks.find(
            (b) => !isScheduledBreakClocked(this.activeTimesheet, b.id),
          ) ?? null
        : null;
    },

    minutesToGo(): string {
      const minutes = this.activeShift
        ? minutesBetweenDates(
            this.activeShift.startsAt,
            this.now.time,
            this.timezone,
          )
        : 0;
      return minutes === 0 ? '' : presentMinutes(Math.abs(minutes));
    },
    isWithinClockInThreshold(): boolean {
      return isWithinClockInThreshold(
        this.earlyClockInThresholdInMinutes,
        this.activeShift,
        this.now.time.getTime(),
      );
    },

    showStartBreakButton(): boolean {
      if (!this.activeShift) {
        return true;
      }
      if (!this.sortedScheduledBreaks.length) {
        return false;
      }
      const shiftHasAnyBreakToClock =
        this.activeTimesheet &&
        this.sortedScheduledBreaks.some(
          (sb) => !isScheduledBreakClocked(this.activeTimesheet, sb.id),
        );

      return shiftHasAnyBreakToClock;
    },

    tooEarlyToStartAllBreaks(): boolean {
      if (this.sortedScheduledBreaks.length && this.activeTimesheet) {
        // check if all of the breaks (besides already clocked) have start time and needed to be started later
        return this.sortedScheduledBreaks
          .filter((sb) => !isScheduledBreakClocked(this.activeTimesheet, sb.id))
          .every((sb) =>
            tooEarlyToStartScheduledBreak(sb, this.now.time, this.timezone),
          );
      }
      return false;
    },

    scheduledBreakRows(): Array<BreakDetailsRowType> {
      if (this.sortedScheduledBreaks.length) {
        return this.sortedScheduledBreaks.map((b) => {
          const { id, paid, startsAt, durationInMinutes } = b;

          let icon: SimpleBubbleIconType | null = null;
          if (
            this.activeTimesheet &&
            isScheduledBreakClocked(this.activeTimesheet, id)
          ) {
            const isBreakInProgress = isScheduledBreakInProgress(
              this.activeTimesheet,
              id,
            );
            icon = isBreakInProgress
              ? { icon: Icon.Clock, colour: 'bright-yellow' }
              : { icon: Icon.Check, colour: 'bright-green' };
          }

          return this.getBreakDetailsRowColumns(
            paid,
            startsAt,
            durationInMinutes,
            icon,
          );
        });
      }
      return [];
    },
    unscheduledBreakRows(): Array<BreakDetailsRowType> {
      const { breaks } = this.activeTimesheet || {};
      // return rows for timesheet breaks only if shift is unscheduled
      if (breaks?.length && !this.activeShift) {
        return breaks.map((b) => {
          const { startedAt, endedAt } = b;

          const durationInMinutes: number | null = endedAt
            ? spacetime(startedAt, this.timezone).diff(endedAt).minutes
            : null;
          const icon: SimpleBubbleIconType = endedAt
            ? { icon: Icon.Check, colour: 'bright-green' }
            : { icon: Icon.Clock, colour: 'bright-yellow' };

          return this.getBreakDetailsRowColumns(
            false, // unscheduled breaks are always unpaid
            startedAt,
            durationInMinutes,
            icon,
          );
        });
      }
      return [];
    },
    breakDetailsRows(): Array<BreakDetailsRowType> {
      return this.scheduledBreakRows.length
        ? this.scheduledBreakRows
        : this.unscheduledBreakRows;
    },

    bottomLabel(): { value: string; icon: IconType } | null {
      const {
        status: timesheetStatus,
        startedAt: timesheetStartedAt,
        breaks: timesheetBreaks,
      } = this.activeTimesheet || {};

      if (timesheetStatus === TimesheetEntryStatusEnum.Started) {
        return {
          value: this.$tc('info.clockedInAt', 1, {
            time: this.$d(timesheetStartedAt, dateTimeFormats.hourMinute),
          }),
          icon: Icon.StopWatch,
        };
      }
      if (timesheetStatus === TimesheetEntryStatusEnum.Break) {
        const breakInProgress = timesheetBreaks.find((b) => !b.endedAt);
        return {
          value: this.$tc('info.breakStartedAt', 1, {
            time: this.$d(
              breakInProgress.startedAt,
              dateTimeFormats.hourMinute,
            ),
          }),
          icon: Icon.BurgerSoda,
        };
      }
      return null;
    },
  },
  watch: {
    activeTimesheet: {
      deep: true,
      immediate: true,
      async handler(timesheet) {
        if (!timesheet) {
          this.shiftInProgress = null;
          return;
        }
        this.shiftInProgress = timesheet.shiftId
          ? await realtimeFindEntity(Entity.Shift, timesheet.shiftId)
          : null;
      },
    },
  },
  mounted() {
    this.now.init();
  },
  beforeDestroy() {
    this.now.destroy();
  },
  methods: {
    getEarliestClockInTimeString,
    addMinutes,

    clockInScheduledShift(shift: Shift): void {
      const type = isWithinClockInThreshold(
        this.earlyClockInThresholdInMinutes,
        shift,
      )
        ? ClockInDialogTypeEnum.ClockIn
        : ClockInDialogTypeEnum.EarlyClockIn;

      // The ClockInDialogV2.vue dialog controls whether the user is within clocking distance
      const clockIn = (location, note, coordinates) => {
        this.clockInDialog = null;
        this.recordClockIn(
          shift,
          location ?? getEntity(shift.locationId, this.locations),
          note,
          coordinates,
        );
      };
      this.clockInDialog = {
        show: true,
        shift,
        type,
        locationId: shift.locationId,
        action:
          type === ClockInDialogTypeEnum.EarlyClockIn
            ? () => {
                this.clockInDialog = null;
              }
            : clockIn,
      };
      // Close the quick clock in window
      this.$emit('close');
    },
    unscheduledClockIn(): void {
      const filteredEmployeeLocations = this.locations.filter(
        clockInFromPersonalDevicesAtLocationFilter(this.loggedInEmployee),
      );
      // make sure there is at least 1 suitable location
      if (filteredEmployeeLocations.length) {
        this.clockInDialog = {
          show: true,
          shift: null,
          ...(filteredEmployeeLocations.length > 1
            ? {
                locationId: null,
                type: ClockInDialogTypeEnum.ChooseLocation,
              }
            : {
                locationId: filteredEmployeeLocations[0].id,
                type: ClockInDialogTypeEnum.ClockIn,
              }),
          action: async (location: Location | null, note, coordinates) => {
            // If location is null then use the first the employee has
            if (!location) {
              [location] = filteredEmployeeLocations;
            }
            const clockIn = async (_, note, coordinates) => {
              this.clockInDialog = null;
              await this.recordClockIn(null, location, note, coordinates);
            };
            // If we just chose location then we need to validate GPS
            if (
              this.clockInDialog.type ===
                ClockInDialogTypeEnum.ChooseLocation &&
              location?.clockInGeoFenceInMeters
            ) {
              // Clearing clockInDialog and using nextTick ensures the ClockInDialog remounts to check GPS location
              this.clockInDialog = null;
              this.$nextTick(() => {
                this.clockInDialog = {
                  show: true,
                  shift: null,
                  type: ClockInDialogTypeEnum.ClockIn,
                  locationId: location.id,
                  action: clockIn,
                };
              });
              return;
            }
            await clockIn(null, note, coordinates);
          },
        };
      }
      // Close the quick clock in window
      this.$emit('close');
    },
    async recordClockIn(
      shift: Shift | null,
      shiftLocation: Location,
      notes: string,
      coordinates: number[] = [],
    ) {
      await clockingsApi
        .recordClockIn({
          clockInData: {
            shiftId: shift?.id ?? null,
            notes: notes || undefined,
            ...(!shift && { locationId: shiftLocation.id }),
            ...(coordinates.length >= 1 && {
              clockingLongitude: coordinates[0],
            }),
            ...(coordinates.length >= 2 && {
              clockingLatitude: coordinates[1],
            }),
          },
        })
        .then(({ data: timesheet }) => {
          realtimeQueryInstantUpdate(
            Entity.TimesheetEntry,
            WeakEntityRepositoryUpdateKind.UPDATE,
            timesheet,
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    clockOut(): void {
      this.clockInDialog = {
        show: true,
        type: ClockInDialogTypeEnum.ClockOut,
        shift: this.activeShift,
        locationId: this.activeTimesheet.locationId,
        action: async (_, notes, coordinates = []) => {
          this.clockInDialog = null;
          this.loading = true;
          await clockingsApi
            .recordClockOut({
              clockOutData: {
                notes: notes || undefined,
                ...(coordinates.length >= 1 && {
                  clockingLongitude: coordinates[0],
                }),
                ...(coordinates.length >= 2 && {
                  clockingLatitude: coordinates[1],
                }),
              },
            })
            .then(({ data: timesheet }) => {
              realtimeQueryInstantUpdate(
                Entity.TimesheetEntry,
                WeakEntityRepositoryUpdateKind.UPDATE,
                timesheet,
              );
            })
            .finally(() => {
              this.loading = false;
            });
        },
      };
      // Close the quick clock in window
      this.$emit('close');
    },
    earlyClockBreak(scheduledBreakId: number | null = null): void {
      this.clockInDialog = {
        show: true,
        type: ClockInDialogTypeEnum.EarlyStartBreak,
        shift: this.activeShift,
        // prop to show scheduled break's minimum start time in ClockInDialog
        ...(scheduledBreakId && { earlyBreakId: scheduledBreakId }),
        action: () => {
          this.clockInDialog = null;
        },
      };
      // Close the quick clock in window
      this.$emit('close');
    },
    clockBreak(): void {
      let clockInDialogType: ClockInDialogTypeEnum =
        ClockInDialogTypeEnum.StartBreak;

      if (this.activeTimesheet.status === TimesheetEntryStatusEnum.Started) {
        if (this.sortedScheduledBreaks.length > 1) {
          clockInDialogType = ClockInDialogTypeEnum.ChooseBreak;
        }
      } else {
        clockInDialogType = ClockInDialogTypeEnum.EndBreak;
      }

      this.clockInDialog = {
        show: true,
        type: clockInDialogType,
        shift: this.activeShift,
        timesheet: this.activeTimesheet,
        locationId: this.activeTimesheet.locationId,
        action: async (
          scheduledBreak: ScheduledBreak | null = null,
          notes: string = '',
          coordinates: number[] = [],
        ) => {
          const { id: scheduledBreakId = null } = scheduledBreak || {};
          // prevent early break start
          if (
            this.activeTimesheet.status === TimesheetEntryStatusEnum.Started &&
            tooEarlyToStartScheduledBreak(
              scheduledBreak,
              this.now.time,
              this.timezone,
            )
          ) {
            this.earlyClockBreak(scheduledBreakId);
            return;
          }

          this.clockInDialog = null;
          this.loading = true;
          await clockingsApi
            .recordBreak({
              recordBreakData: {
                scheduledBreakId,
                notes: notes || undefined,
                ...(coordinates.length >= 1 && {
                  clockingLongitude: coordinates[0],
                }),
                ...(coordinates.length >= 2 && {
                  clockingLatitude: coordinates[1],
                }),
              },
            })
            .then(({ data: timesheet }) => {
              realtimeQueryInstantUpdate(
                Entity.TimesheetEntry,
                WeakEntityRepositoryUpdateKind.UPDATE,
                timesheet,
              );
            })
            .finally(() => {
              this.loading = false;
            });
        },
      };
      // Close the quick clock in window
      this.$emit('close');
    },

    getBreakDetailsRowColumns(
      paid: boolean,
      startTime: Date | null = null,
      durationInMinutes: number | null = null,
      icon: SimpleBubbleIconType | null = null,
    ): BreakDetailsRowType {
      const breakName = paid ? this.paidBreakName : this.unpaidBreakName;
      const breakLabel = paid
        ? this.$tc('label.paid')
        : this.$tc('label.unpaid');
      const durationIsNumber = Number.isInteger(durationInMinutes);
      const endTime =
        startTime && durationIsNumber
          ? addMinutes(startTime, durationInMinutes, this.timezone)
          : null;
      const isUnscheduledShift = !this.activeShift;

      return [
        {
          header: breakName ?? this.$tc('label.break'),
          info: breakLabel,
          minWidth: 'min-w-[15%]',
        },
        {
          ...((isUnscheduledShift || durationIsNumber) && {
            header: this.$tc('label.duration'),
          }),
          ...(durationIsNumber && {
            info: this.$tc('unitWithAmount.min', durationInMinutes),
          }),
          minWidth: 'min-w-[17%]',
        },
        {
          ...((isUnscheduledShift || startTime) && {
            header: this.$tc('label.start'),
          }),
          ...(startTime && {
            info: this.$d(startTime, dateTimeFormats.hourMinute),
          }),
          minWidth: 'min-w-[12%]',
        },
        {
          ...((isUnscheduledShift || endTime) && {
            header: this.$tc('label.end'),
          }),
          ...(endTime && {
            info: this.$d(endTime, dateTimeFormats.hourMinute),
          }),
          minWidth: 'min-w-[12%]',
        },
        {
          minWidth: 'min-w-[7%]',
          ...(icon && { icon }),
        },
      ];
    },
  },
};
