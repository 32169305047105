/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LeavePeriodType
 */
export interface LeavePeriodType {
    /**
     * 
     * @type {number}
     * @memberof LeavePeriodType
     */
    originalLeaveTypeId: number;
    /**
     * 
     * @type {number}
     * @memberof LeavePeriodType
     */
    deductionLeavePeriodTypeId: number | null;
    /**
     * 
     * @type {string}
     * @memberof LeavePeriodType
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof LeavePeriodType
     */
    colour: string | null;
    /**
     * 
     * @type {string}
     * @memberof LeavePeriodType
     */
    status: LeavePeriodTypeStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof LeavePeriodType
     */
    paid: boolean;
    /**
     * 
     * @type {string}
     * @memberof LeavePeriodType
     */
    payCode: string | null;
    /**
     * 
     * @type {string}
     * @memberof LeavePeriodType
     */
    displayIcon: string | null;
    /**
     * 
     * @type {string}
     * @memberof LeavePeriodType
     */
    leaveAllowanceType: LeavePeriodTypeLeaveAllowanceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof LeavePeriodType
     */
    leaveAllowanceUnit: LeavePeriodTypeLeaveAllowanceUnitEnum;
    /**
     * Leave entitlement for this leave type when using the 'Fixed' allowance type.
     * @type {number}
     * @memberof LeavePeriodType
     */
    leaveAllowance: number | null;
    /**
     * 
     * @type {number}
     * @memberof LeavePeriodType
     */
    accrualRatePerHour: number | null;
    /**
     * 
     * @type {number}
     * @memberof LeavePeriodType
     */
    accrualLimitDaysPerPeriod: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof LeavePeriodType
     */
    accrualAllowedOnOvertimeHours: boolean;
}

/**
* @export
* @enum {string}
*/
export enum LeavePeriodTypeStatusEnum {
    Active = 'Active',
    Archived = 'Archived',
    Deleted = 'Deleted'
}
/**
* @export
* @enum {string}
*/
export enum LeavePeriodTypeLeaveAllowanceTypeEnum {
    Accrued = 'Accrued',
    DeductsFromAnother = 'Deducts From Another',
    Fixed = 'Fixed',
    Unlimited = 'Unlimited'
}
/**
* @export
* @enum {string}
*/
export enum LeavePeriodTypeLeaveAllowanceUnitEnum {
    Days = 'Days',
    Hours = 'Hours'
}


/**
 * Check if a given object implements the LeavePeriodType interface.
 */
export function instanceOfLeavePeriodType(value: object): value is LeavePeriodType {
    if (!('originalLeaveTypeId' in value) || value['originalLeaveTypeId'] === undefined) return false;
    if (!('deductionLeavePeriodTypeId' in value) || value['deductionLeavePeriodTypeId'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('colour' in value) || value['colour'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('paid' in value) || value['paid'] === undefined) return false;
    if (!('payCode' in value) || value['payCode'] === undefined) return false;
    if (!('displayIcon' in value) || value['displayIcon'] === undefined) return false;
    if (!('leaveAllowanceType' in value) || value['leaveAllowanceType'] === undefined) return false;
    if (!('leaveAllowanceUnit' in value) || value['leaveAllowanceUnit'] === undefined) return false;
    if (!('leaveAllowance' in value) || value['leaveAllowance'] === undefined) return false;
    if (!('accrualRatePerHour' in value) || value['accrualRatePerHour'] === undefined) return false;
    if (!('accrualLimitDaysPerPeriod' in value) || value['accrualLimitDaysPerPeriod'] === undefined) return false;
    if (!('accrualAllowedOnOvertimeHours' in value) || value['accrualAllowedOnOvertimeHours'] === undefined) return false;
    return true;
}

export function LeavePeriodTypeFromJSON(json: any): LeavePeriodType {
    return LeavePeriodTypeFromJSONTyped(json, false);
}

export function LeavePeriodTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeavePeriodType {
    if (json == null) {
        return json;
    }
    return {
        
        'originalLeaveTypeId': json['originalLeaveTypeId'],
        'deductionLeavePeriodTypeId': json['deductionLeavePeriodTypeId'],
        'name': json['name'],
        'colour': json['colour'],
        'status': json['status'],
        'paid': json['paid'],
        'payCode': json['payCode'],
        'displayIcon': json['displayIcon'],
        'leaveAllowanceType': json['leaveAllowanceType'],
        'leaveAllowanceUnit': json['leaveAllowanceUnit'],
        'leaveAllowance': json['leaveAllowance'],
        'accrualRatePerHour': json['accrualRatePerHour'],
        'accrualLimitDaysPerPeriod': json['accrualLimitDaysPerPeriod'],
        'accrualAllowedOnOvertimeHours': json['accrualAllowedOnOvertimeHours'],
    };
}

  export function LeavePeriodTypeToJSON(json: any): LeavePeriodType {
      return LeavePeriodTypeToJSONTyped(json, false);
  }

  export function LeavePeriodTypeToJSONTyped(value?: LeavePeriodType | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'originalLeaveTypeId': value['originalLeaveTypeId'],
        'deductionLeavePeriodTypeId': value['deductionLeavePeriodTypeId'],
        'name': value['name'],
        'colour': value['colour'],
        'status': value['status'],
        'paid': value['paid'],
        'payCode': value['payCode'],
        'displayIcon': value['displayIcon'],
        'leaveAllowanceType': value['leaveAllowanceType'],
        'leaveAllowanceUnit': value['leaveAllowanceUnit'],
        'leaveAllowance': value['leaveAllowance'],
        'accrualRatePerHour': value['accrualRatePerHour'],
        'accrualLimitDaysPerPeriod': value['accrualLimitDaysPerPeriod'],
        'accrualAllowedOnOvertimeHours': value['accrualAllowedOnOvertimeHours'],
    };
}

