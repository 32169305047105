/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
    ShiftiePlainDateToJSONTyped,
} from './ShiftiePlainDate';

/**
 * Copy Work Pattern Overrides, optionally for an employees.
 * @export
 * @interface CopyCreateWorkPatternOverrideData
 */
export interface CopyCreateWorkPatternOverrideData {
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof CopyCreateWorkPatternOverrideData
     */
    sourceStart: ShiftiePlainDate;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof CopyCreateWorkPatternOverrideData
     */
    sourceEnd: ShiftiePlainDate;
    /**
     * 
     * @type {string}
     * @memberof CopyCreateWorkPatternOverrideData
     */
    offsetUnit: CopyCreateWorkPatternOverrideDataOffsetUnitEnum;
    /**
     * The number of units to offset by. Must be a positive number.
     * @type {number}
     * @memberof CopyCreateWorkPatternOverrideData
     */
    offsetAmount: number;
    /**
     * 
     * @type {number}
     * @memberof CopyCreateWorkPatternOverrideData
     */
    employeeId?: number | null;
}

/**
* @export
* @enum {string}
*/
export enum CopyCreateWorkPatternOverrideDataOffsetUnitEnum {
    Day = 'Day',
    Week = 'Week'
}


/**
 * Check if a given object implements the CopyCreateWorkPatternOverrideData interface.
 */
export function instanceOfCopyCreateWorkPatternOverrideData(value: object): value is CopyCreateWorkPatternOverrideData {
    if (!('sourceStart' in value) || value['sourceStart'] === undefined) return false;
    if (!('sourceEnd' in value) || value['sourceEnd'] === undefined) return false;
    if (!('offsetUnit' in value) || value['offsetUnit'] === undefined) return false;
    if (!('offsetAmount' in value) || value['offsetAmount'] === undefined) return false;
    return true;
}

export function CopyCreateWorkPatternOverrideDataFromJSON(json: any): CopyCreateWorkPatternOverrideData {
    return CopyCreateWorkPatternOverrideDataFromJSONTyped(json, false);
}

export function CopyCreateWorkPatternOverrideDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CopyCreateWorkPatternOverrideData {
    if (json == null) {
        return json;
    }
    return {
        
        'sourceStart': ShiftiePlainDateFromJSON(json['sourceStart']),
        'sourceEnd': ShiftiePlainDateFromJSON(json['sourceEnd']),
        'offsetUnit': json['offsetUnit'],
        'offsetAmount': json['offsetAmount'],
        'employeeId': json['employeeId'] == null ? undefined : json['employeeId'],
    };
}

  export function CopyCreateWorkPatternOverrideDataToJSON(json: any): CopyCreateWorkPatternOverrideData {
      return CopyCreateWorkPatternOverrideDataToJSONTyped(json, false);
  }

  export function CopyCreateWorkPatternOverrideDataToJSONTyped(value?: CopyCreateWorkPatternOverrideData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'sourceStart': ShiftiePlainDateToJSON(value['sourceStart']),
        'sourceEnd': ShiftiePlainDateToJSON(value['sourceEnd']),
        'offsetUnit': value['offsetUnit'],
        'offsetAmount': value['offsetAmount'],
        'employeeId': value['employeeId'],
    };
}

