/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BulkUpdateShiftsDataUpdateData
 */
export interface BulkUpdateShiftsDataUpdateData {
    /**
     * 
     * @type {boolean}
     * @memberof BulkUpdateShiftsDataUpdateData
     */
    acknowledged?: boolean;
}

/**
 * Check if a given object implements the BulkUpdateShiftsDataUpdateData interface.
 */
export function instanceOfBulkUpdateShiftsDataUpdateData(value: object): value is BulkUpdateShiftsDataUpdateData {
    return true;
}

export function BulkUpdateShiftsDataUpdateDataFromJSON(json: any): BulkUpdateShiftsDataUpdateData {
    return BulkUpdateShiftsDataUpdateDataFromJSONTyped(json, false);
}

export function BulkUpdateShiftsDataUpdateDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkUpdateShiftsDataUpdateData {
    if (json == null) {
        return json;
    }
    return {
        
        'acknowledged': json['acknowledged'] == null ? undefined : json['acknowledged'],
    };
}

  export function BulkUpdateShiftsDataUpdateDataToJSON(json: any): BulkUpdateShiftsDataUpdateData {
      return BulkUpdateShiftsDataUpdateDataToJSONTyped(json, false);
  }

  export function BulkUpdateShiftsDataUpdateDataToJSONTyped(value?: BulkUpdateShiftsDataUpdateData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'acknowledged': value['acknowledged'],
    };
}

