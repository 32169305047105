<template>
  <v-bottom-sheet
    v-model="open"
    :inset="viewport.md"
    :scrollable="scrollable"
  >
    <v-sheet
      class="mobile-padding"
      @click="persistent ? null : (open = false)"
    >
      <slot />
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
import { viewport } from '@/util/windowFunctions';

export default {
  name: 'BottomSheet',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    persistent: {
      type: Boolean,
      default: false,
    },
    scrollable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      viewport,
    };
  },
  computed: {
    open: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('input', v);
        this.$emit('close');
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.mobile-padding {
  padding-bottom: calc(var(--deviceSafeAreaBottom));
}
</style>
