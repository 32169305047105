/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CopyShiftsData
 */
export interface CopyShiftsData {
    /**
     * 
     * @type {number}
     * @memberof CopyShiftsData
     */
    scheduleId: number;
    /**
     * 
     * @type {number}
     * @memberof CopyShiftsData
     */
    employeeId?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof CopyShiftsData
     */
    includes?: Array<CopyShiftsDataIncludesEnum>;
    /**
     * Where to start copying from (inclusive)
     * @type {Date}
     * @memberof CopyShiftsData
     */
    sourceStart: Date;
    /**
     * Where to finish copying (exclusive)
     * @type {Date}
     * @memberof CopyShiftsData
     */
    sourceEnd: Date;
    /**
     * 
     * @type {string}
     * @memberof CopyShiftsData
     */
    offsetUnit: CopyShiftsDataOffsetUnitEnum;
    /**
     * The number of units to offset by. Can be negative. Cannot be zero.
     * @type {number}
     * @memberof CopyShiftsData
     */
    offsetAmount: number;
    /**
     * 
     * @type {string}
     * @memberof CopyShiftsData
     */
    conflictMode: CopyShiftsDataConflictModeEnum;
}

/**
* @export
* @enum {string}
*/
export enum CopyShiftsDataIncludesEnum {
    Tags = 'tags',
    ScheduledBreaks = 'scheduledBreaks',
    ShiftAreaSessions = 'shiftAreaSessions'
}
/**
* @export
* @enum {string}
*/
export enum CopyShiftsDataOffsetUnitEnum {
    Day = 'Day',
    Week = 'Week'
}
/**
* @export
* @enum {string}
*/
export enum CopyShiftsDataConflictModeEnum {
    Omit = 'Omit',
    Overwrite = 'Overwrite',
    Unassign = 'Unassign',
    UnassignAll = 'Unassign All'
}


/**
 * Check if a given object implements the CopyShiftsData interface.
 */
export function instanceOfCopyShiftsData(value: object): value is CopyShiftsData {
    if (!('scheduleId' in value) || value['scheduleId'] === undefined) return false;
    if (!('sourceStart' in value) || value['sourceStart'] === undefined) return false;
    if (!('sourceEnd' in value) || value['sourceEnd'] === undefined) return false;
    if (!('offsetUnit' in value) || value['offsetUnit'] === undefined) return false;
    if (!('offsetAmount' in value) || value['offsetAmount'] === undefined) return false;
    if (!('conflictMode' in value) || value['conflictMode'] === undefined) return false;
    return true;
}

export function CopyShiftsDataFromJSON(json: any): CopyShiftsData {
    return CopyShiftsDataFromJSONTyped(json, false);
}

export function CopyShiftsDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CopyShiftsData {
    if (json == null) {
        return json;
    }
    return {
        
        'scheduleId': json['scheduleId'],
        'employeeId': json['employeeId'] == null ? undefined : json['employeeId'],
        'includes': json['includes'] == null ? undefined : json['includes'],
        'sourceStart': (new Date(json['sourceStart'])),
        'sourceEnd': (new Date(json['sourceEnd'])),
        'offsetUnit': json['offsetUnit'],
        'offsetAmount': json['offsetAmount'],
        'conflictMode': json['conflictMode'],
    };
}

  export function CopyShiftsDataToJSON(json: any): CopyShiftsData {
      return CopyShiftsDataToJSONTyped(json, false);
  }

  export function CopyShiftsDataToJSONTyped(value?: CopyShiftsData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'scheduleId': value['scheduleId'],
        'employeeId': value['employeeId'],
        'includes': value['includes'],
        'sourceStart': ((value['sourceStart']).toISOString()),
        'sourceEnd': ((value['sourceEnd']).toISOString()),
        'offsetUnit': value['offsetUnit'],
        'offsetAmount': value['offsetAmount'],
        'conflictMode': value['conflictMode'],
    };
}

