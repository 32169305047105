/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RecordBreakData
 */
export interface RecordBreakData {
    /**
     * 
     * @type {number}
     * @memberof RecordBreakData
     */
    scheduledBreakId: number | null;
    /**
     * Latitude of the location where the event occurred.
     * @type {number}
     * @memberof RecordBreakData
     */
    clockingLatitude?: number | null;
    /**
     * Longitude of the location where the event occurred.
     * @type {number}
     * @memberof RecordBreakData
     */
    clockingLongitude?: number | null;
    /**
     * Any notes that the employee wants to add to the associated timesheet.
     * @type {string}
     * @memberof RecordBreakData
     */
    notes?: string | null;
}

/**
 * Check if a given object implements the RecordBreakData interface.
 */
export function instanceOfRecordBreakData(value: object): value is RecordBreakData {
    if (!('scheduledBreakId' in value) || value['scheduledBreakId'] === undefined) return false;
    return true;
}

export function RecordBreakDataFromJSON(json: any): RecordBreakData {
    return RecordBreakDataFromJSONTyped(json, false);
}

export function RecordBreakDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecordBreakData {
    if (json == null) {
        return json;
    }
    return {
        
        'scheduledBreakId': json['scheduledBreakId'],
        'clockingLatitude': json['clockingLatitude'] == null ? undefined : json['clockingLatitude'],
        'clockingLongitude': json['clockingLongitude'] == null ? undefined : json['clockingLongitude'],
        'notes': json['notes'] == null ? undefined : json['notes'],
    };
}

  export function RecordBreakDataToJSON(json: any): RecordBreakData {
      return RecordBreakDataToJSONTyped(json, false);
  }

  export function RecordBreakDataToJSONTyped(value?: RecordBreakData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'scheduledBreakId': value['scheduledBreakId'],
        'clockingLatitude': value['clockingLatitude'],
        'clockingLongitude': value['clockingLongitude'],
        'notes': value['notes'],
    };
}

