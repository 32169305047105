/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * A reason for an Employee to be on leave
 * @export
 * @interface LeaveType
 */
export interface LeaveType {
    /**
     * 
     * @type {number}
     * @memberof LeaveType
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof LeaveType
     */
    companyId: number;
    /**
     * 
     * @type {number}
     * @memberof LeaveType
     */
    deductionLeaveTypeId: number | null;
    /**
     * 
     * @type {string}
     * @memberof LeaveType
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof LeaveType
     */
    status: LeaveTypeStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof LeaveType
     */
    colour: string;
    /**
     * 
     * @type {boolean}
     * @memberof LeaveType
     */
    paid: boolean;
    /**
     * 
     * @type {string}
     * @memberof LeaveType
     */
    payCode: string | null;
    /**
     * 
     * @type {string}
     * @memberof LeaveType
     */
    displayIcon: LeaveTypeDisplayIconEnum;
    /**
     * 
     * @type {string}
     * @memberof LeaveType
     */
    leaveAllowanceType: LeaveTypeLeaveAllowanceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof LeaveType
     */
    leaveAllowanceUnit: LeaveTypeLeaveAllowanceUnitEnum;
    /**
     * Leave entitlement for this leave type when using the 'Fixed' allowance type.
     * @type {number}
     * @memberof LeaveType
     */
    leaveAllowance: number | null;
    /**
     * 
     * @type {number}
     * @memberof LeaveType
     */
    accrualRatePerHour: number | null;
    /**
     * 
     * @type {number}
     * @memberof LeaveType
     */
    accrualLimitDaysPerPeriod: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof LeaveType
     */
    accrualAllowedOnOvertimeHours: boolean;
}

/**
* @export
* @enum {string}
*/
export enum LeaveTypeStatusEnum {
    Active = 'Active',
    Archived = 'Archived',
    Deleted = 'Deleted'
}
/**
* @export
* @enum {string}
*/
export enum LeaveTypeDisplayIconEnum {
    SolidFaBaby = 'fa-solid fa-baby',
    SolidFaBabyCarriage = 'fa-solid fa-baby-carriage',
    SolidFaBasketShoppingSimple = 'fa-solid fa-basket-shopping-simple',
    SolidFaBed = 'fa-solid fa-bed',
    SolidFaBriefcase = 'fa-solid fa-briefcase',
    SolidFaCakeCandles = 'fa-solid fa-cake-candles',
    SolidFaCar = 'fa-solid fa-car',
    SolidFaCarBus = 'fa-solid fa-car-bus',
    SolidFaClock = 'fa-solid fa-clock',
    SolidFaEarthEurope = 'fa-solid fa-earth-europe',
    SolidFaFaceFrownSlight = 'fa-solid fa-face-frown-slight',
    SolidFaFaceThermometer = 'fa-solid fa-face-thermometer',
    SharpFaSolidFaGavel = 'fa-sharp fa-solid fa-gavel',
    SolidFaGift = 'fa-solid fa-gift',
    SolidFaGraduationCap = 'fa-solid fa-graduation-cap',
    SolidFaHandsPraying = 'fa-solid fa-hands-praying',
    SolidFaHandHoldingHeart = 'fa-solid fa-hand-holding-heart',
    SolidFaHeadSideGear = 'fa-solid fa-head-side-gear',
    SolidFaHouse = 'fa-solid fa-house',
    SolidFaPaw = 'fa-solid fa-paw',
    SharpFaSolidFaPersonBreastfeeding = 'fa-sharp fa-solid fa-person-breastfeeding',
    SharpFaSolidFaPersonPregnant = 'fa-sharp fa-solid fa-person-pregnant',
    SolidFaPlane = 'fa-solid fa-plane',
    SolidFaSnowflake = 'fa-solid fa-snowflake',
    SolidFaSpa = 'fa-solid fa-spa',
    SolidFaSquarePlus = 'fa-solid fa-square-plus',
    SolidFaSuitcaseRolling = 'fa-solid fa-suitcase-rolling',
    SolidFaUmbrellaBeach = 'fa-solid fa-umbrella-beach',
    SolidFaUsers = 'fa-solid fa-users',
    SolidFaVirus = 'fa-solid fa-virus'
}
/**
* @export
* @enum {string}
*/
export enum LeaveTypeLeaveAllowanceTypeEnum {
    Accrued = 'Accrued',
    DeductsFromAnother = 'Deducts From Another',
    Fixed = 'Fixed',
    Unlimited = 'Unlimited'
}
/**
* @export
* @enum {string}
*/
export enum LeaveTypeLeaveAllowanceUnitEnum {
    Days = 'Days',
    Hours = 'Hours'
}


/**
 * Check if a given object implements the LeaveType interface.
 */
export function instanceOfLeaveType(value: object): value is LeaveType {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('companyId' in value) || value['companyId'] === undefined) return false;
    if (!('deductionLeaveTypeId' in value) || value['deductionLeaveTypeId'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('colour' in value) || value['colour'] === undefined) return false;
    if (!('paid' in value) || value['paid'] === undefined) return false;
    if (!('payCode' in value) || value['payCode'] === undefined) return false;
    if (!('displayIcon' in value) || value['displayIcon'] === undefined) return false;
    if (!('leaveAllowanceType' in value) || value['leaveAllowanceType'] === undefined) return false;
    if (!('leaveAllowanceUnit' in value) || value['leaveAllowanceUnit'] === undefined) return false;
    if (!('leaveAllowance' in value) || value['leaveAllowance'] === undefined) return false;
    if (!('accrualRatePerHour' in value) || value['accrualRatePerHour'] === undefined) return false;
    if (!('accrualLimitDaysPerPeriod' in value) || value['accrualLimitDaysPerPeriod'] === undefined) return false;
    if (!('accrualAllowedOnOvertimeHours' in value) || value['accrualAllowedOnOvertimeHours'] === undefined) return false;
    return true;
}

export function LeaveTypeFromJSON(json: any): LeaveType {
    return LeaveTypeFromJSONTyped(json, false);
}

export function LeaveTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeaveType {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'deductionLeaveTypeId': json['deductionLeaveTypeId'],
        'name': json['name'],
        'status': json['status'],
        'colour': json['colour'],
        'paid': json['paid'],
        'payCode': json['payCode'],
        'displayIcon': json['displayIcon'],
        'leaveAllowanceType': json['leaveAllowanceType'],
        'leaveAllowanceUnit': json['leaveAllowanceUnit'],
        'leaveAllowance': json['leaveAllowance'],
        'accrualRatePerHour': json['accrualRatePerHour'],
        'accrualLimitDaysPerPeriod': json['accrualLimitDaysPerPeriod'],
        'accrualAllowedOnOvertimeHours': json['accrualAllowedOnOvertimeHours'],
    };
}

  export function LeaveTypeToJSON(json: any): LeaveType {
      return LeaveTypeToJSONTyped(json, false);
  }

  export function LeaveTypeToJSONTyped(value?: LeaveType | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'deductionLeaveTypeId': value['deductionLeaveTypeId'],
        'name': value['name'],
        'status': value['status'],
        'colour': value['colour'],
        'paid': value['paid'],
        'payCode': value['payCode'],
        'displayIcon': value['displayIcon'],
        'leaveAllowanceType': value['leaveAllowanceType'],
        'leaveAllowanceUnit': value['leaveAllowanceUnit'],
        'leaveAllowance': value['leaveAllowance'],
        'accrualRatePerHour': value['accrualRatePerHour'],
        'accrualLimitDaysPerPeriod': value['accrualLimitDaysPerPeriod'],
        'accrualAllowedOnOvertimeHours': value['accrualAllowedOnOvertimeHours'],
    };
}

