/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { EmployeePersonalDetails } from './EmployeePersonalDetails';
import {
    EmployeePersonalDetailsFromJSON,
    EmployeePersonalDetailsFromJSONTyped,
    EmployeePersonalDetailsToJSON,
    EmployeePersonalDetailsToJSONTyped,
} from './EmployeePersonalDetails';
import type { EmployeesJobRole } from './EmployeesJobRole';
import {
    EmployeesJobRoleFromJSON,
    EmployeesJobRoleFromJSONTyped,
    EmployeesJobRoleToJSON,
    EmployeesJobRoleToJSONTyped,
} from './EmployeesJobRole';
import type { Company } from './Company';
import {
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
    CompanyToJSONTyped,
} from './Company';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
    ShiftiePlainDateToJSONTyped,
} from './ShiftiePlainDate';
import type { User } from './User';
import {
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
    UserToJSONTyped,
} from './User';
import type { AccessRole } from './AccessRole';
import {
    AccessRoleFromJSON,
    AccessRoleFromJSONTyped,
    AccessRoleToJSON,
    AccessRoleToJSONTyped,
} from './AccessRole';
import type { EmployeeAttribute } from './EmployeeAttribute';
import {
    EmployeeAttributeFromJSON,
    EmployeeAttributeFromJSONTyped,
    EmployeeAttributeToJSON,
    EmployeeAttributeToJSONTyped,
} from './EmployeeAttribute';
import type { WorkPattern } from './WorkPattern';
import {
    WorkPatternFromJSON,
    WorkPatternFromJSONTyped,
    WorkPatternToJSON,
    WorkPatternToJSONTyped,
} from './WorkPattern';
import type { Upload } from './Upload';
import {
    UploadFromJSON,
    UploadFromJSONTyped,
    UploadToJSON,
    UploadToJSONTyped,
} from './Upload';
import type { EmployeeOnboarding } from './EmployeeOnboarding';
import {
    EmployeeOnboardingFromJSON,
    EmployeeOnboardingFromJSONTyped,
    EmployeeOnboardingToJSON,
    EmployeeOnboardingToJSONTyped,
} from './EmployeeOnboarding';
import type { Notification } from './Notification';
import {
    NotificationFromJSON,
    NotificationFromJSONTyped,
    NotificationToJSON,
    NotificationToJSONTyped,
} from './Notification';
import type { JobRole } from './JobRole';
import {
    JobRoleFromJSON,
    JobRoleFromJSONTyped,
    JobRoleToJSON,
    JobRoleToJSONTyped,
} from './JobRole';
import type { EmployeeGroup } from './EmployeeGroup';
import {
    EmployeeGroupFromJSON,
    EmployeeGroupFromJSONTyped,
    EmployeeGroupToJSON,
    EmployeeGroupToJSONTyped,
} from './EmployeeGroup';
import type { Schedule } from './Schedule';
import {
    ScheduleFromJSON,
    ScheduleFromJSONTyped,
    ScheduleToJSON,
    ScheduleToJSONTyped,
} from './Schedule';
import type { Location } from './Location';
import {
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
    LocationToJSONTyped,
} from './Location';

/**
 * Represents a User working for a Company
 * @export
 * @interface Employee
 */
export interface Employee {
    /**
     * 
     * @type {number}
     * @memberof Employee
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof Employee
     */
    companyId: number;
    /**
     * 
     * @type {number}
     * @memberof Employee
     */
    userId: number | null;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    lastName: string;
    /**
     * A unique reference given to the employee by the company
     * @type {string}
     * @memberof Employee
     */
    reference: string | null;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    status: EmployeeStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof Employee
     */
    lineManagerId: number | null;
    /**
     * 
     * @type {number}
     * @memberof Employee
     */
    leaveApproverId: number | null;
    /**
     * 
     * @type {number}
     * @memberof Employee
     */
    workPatternId: number;
    /**
     * 
     * @type {number}
     * @memberof Employee
     */
    employeeGroupId: number | null;
    /**
     * 
     * @type {number}
     * @memberof Employee
     */
    defaultJobRoleId: number;
    /**
     * 
     * @type {number}
     * @memberof Employee
     */
    leavePolicyId: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof Employee
     */
    showContactDetails: boolean;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    contactEmail: string | null;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    phoneNumber: string | null;
    /**
     * 
     * @type {Date}
     * @memberof Employee
     */
    phoneNumberVerifiedAt: Date | null;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    jobTitle: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Employee
     */
    exemptFromOvertime: boolean;
    /**
     * The number of hours this employee would usually work in a week
     * @type {number}
     * @memberof Employee
     */
    workingHoursPerWeek: number;
    /**
     * The number of hours taken from this employee's leave allowance for a day's worth of leave
     * @type {number}
     * @memberof Employee
     */
    leaveHoursUsedPerDay: number;
    /**
     * 
     * @type {string}
     * @memberof Employee
     */
    employmentType: EmployeeEmploymentTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof Employee
     */
    canRequestLeave: boolean;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof Employee
     */
    joinDate: ShiftiePlainDate | null;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof Employee
     */
    probationDate: ShiftiePlainDate | null;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof Employee
     */
    terminationDate: ShiftiePlainDate | null;
    /**
     * 
     * @type {Date}
     * @memberof Employee
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Employee
     */
    updatedAt: Date | null;
    /**
     * 
     * @type {User}
     * @memberof Employee
     */
    user?: User;
    /**
     * 
     * @type {Array<JobRole>}
     * @memberof Employee
     */
    jobRoles?: Array<JobRole>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Employee
     */
    jobRoleIds: Array<number>;
    /**
     * 
     * @type {Array<AccessRole>}
     * @memberof Employee
     */
    accessRoles?: Array<AccessRole>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Employee
     */
    accessRoleIds: Array<number>;
    /**
     * 
     * @type {Array<EmployeeAttribute>}
     * @memberof Employee
     */
    employeeAttributes?: Array<EmployeeAttribute>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Employee
     */
    employeeAttributeIds: Array<number>;
    /**
     * 
     * @type {Upload}
     * @memberof Employee
     */
    photo?: Upload;
    /**
     * 
     * @type {Company}
     * @memberof Employee
     */
    company?: Company;
    /**
     * 
     * @type {EmployeeGroup}
     * @memberof Employee
     */
    employeeGroup?: EmployeeGroup;
    /**
     * 
     * @type {Array<Location>}
     * @memberof Employee
     */
    locations?: Array<Location>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Employee
     */
    locationIds: Array<number>;
    /**
     * 
     * @type {Array<Schedule>}
     * @memberof Employee
     */
    schedules?: Array<Schedule>;
    /**
     * 
     * @type {Array<number>}
     * @memberof Employee
     */
    scheduleIds: Array<number>;
    /**
     * 
     * @type {Array<Notification>}
     * @memberof Employee
     */
    notifications?: Array<Notification>;
    /**
     * 
     * @type {Array<EmployeesJobRole>}
     * @memberof Employee
     */
    employeesJobRoles?: Array<EmployeesJobRole>;
    /**
     * 
     * @type {EmployeeOnboarding}
     * @memberof Employee
     */
    employeeOnboarding?: EmployeeOnboarding;
    /**
     * 
     * @type {EmployeePersonalDetails}
     * @memberof Employee
     */
    employeePersonalDetail?: EmployeePersonalDetails;
    /**
     * 
     * @type {WorkPattern}
     * @memberof Employee
     */
    workPattern?: WorkPattern;
    /**
     * 
     * @type {Date}
     * @memberof Employee
     */
    archivedAt: Date | null;
    /**
     * 
     * @type {number}
     * @memberof Employee
     */
    archivedById: number | null;
}

/**
* @export
* @enum {string}
*/
export enum EmployeeStatusEnum {
    Active = 'Active',
    Archived = 'Archived',
    Deleted = 'Deleted',
    Pending = 'Pending'
}
/**
* @export
* @enum {string}
*/
export enum EmployeeEmploymentTypeEnum {
    Agency = 'Agency',
    AnnualisedContract = 'Annualised Contract',
    ApprenticeTrainee = 'Apprentice / Trainee',
    Casual = 'Casual',
    FixedTerm = 'Fixed Term',
    FullTime = 'Full Time',
    PartTime = 'Part Time',
    SelfEmployed = 'Self-Employed',
    Temporary = 'Temporary',
    Volunteer = 'Volunteer'
}


/**
 * Check if a given object implements the Employee interface.
 */
export function instanceOfEmployee(value: object): value is Employee {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('companyId' in value) || value['companyId'] === undefined) return false;
    if (!('userId' in value) || value['userId'] === undefined) return false;
    if (!('firstName' in value) || value['firstName'] === undefined) return false;
    if (!('lastName' in value) || value['lastName'] === undefined) return false;
    if (!('reference' in value) || value['reference'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('lineManagerId' in value) || value['lineManagerId'] === undefined) return false;
    if (!('leaveApproverId' in value) || value['leaveApproverId'] === undefined) return false;
    if (!('workPatternId' in value) || value['workPatternId'] === undefined) return false;
    if (!('employeeGroupId' in value) || value['employeeGroupId'] === undefined) return false;
    if (!('defaultJobRoleId' in value) || value['defaultJobRoleId'] === undefined) return false;
    if (!('leavePolicyId' in value) || value['leavePolicyId'] === undefined) return false;
    if (!('showContactDetails' in value) || value['showContactDetails'] === undefined) return false;
    if (!('contactEmail' in value) || value['contactEmail'] === undefined) return false;
    if (!('phoneNumber' in value) || value['phoneNumber'] === undefined) return false;
    if (!('phoneNumberVerifiedAt' in value) || value['phoneNumberVerifiedAt'] === undefined) return false;
    if (!('jobTitle' in value) || value['jobTitle'] === undefined) return false;
    if (!('exemptFromOvertime' in value) || value['exemptFromOvertime'] === undefined) return false;
    if (!('workingHoursPerWeek' in value) || value['workingHoursPerWeek'] === undefined) return false;
    if (!('leaveHoursUsedPerDay' in value) || value['leaveHoursUsedPerDay'] === undefined) return false;
    if (!('employmentType' in value) || value['employmentType'] === undefined) return false;
    if (!('canRequestLeave' in value) || value['canRequestLeave'] === undefined) return false;
    if (!('joinDate' in value) || value['joinDate'] === undefined) return false;
    if (!('probationDate' in value) || value['probationDate'] === undefined) return false;
    if (!('terminationDate' in value) || value['terminationDate'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('jobRoleIds' in value) || value['jobRoleIds'] === undefined) return false;
    if (!('accessRoleIds' in value) || value['accessRoleIds'] === undefined) return false;
    if (!('employeeAttributeIds' in value) || value['employeeAttributeIds'] === undefined) return false;
    if (!('locationIds' in value) || value['locationIds'] === undefined) return false;
    if (!('scheduleIds' in value) || value['scheduleIds'] === undefined) return false;
    if (!('archivedAt' in value) || value['archivedAt'] === undefined) return false;
    if (!('archivedById' in value) || value['archivedById'] === undefined) return false;
    return true;
}

export function EmployeeFromJSON(json: any): Employee {
    return EmployeeFromJSONTyped(json, false);
}

export function EmployeeFromJSONTyped(json: any, ignoreDiscriminator: boolean): Employee {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'userId': json['userId'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'reference': json['reference'],
        'status': json['status'],
        'lineManagerId': json['lineManagerId'],
        'leaveApproverId': json['leaveApproverId'],
        'workPatternId': json['workPatternId'],
        'employeeGroupId': json['employeeGroupId'],
        'defaultJobRoleId': json['defaultJobRoleId'],
        'leavePolicyId': json['leavePolicyId'],
        'showContactDetails': json['showContactDetails'],
        'contactEmail': json['contactEmail'],
        'phoneNumber': json['phoneNumber'],
        'phoneNumberVerifiedAt': (json['phoneNumberVerifiedAt'] == null ? null : new Date(json['phoneNumberVerifiedAt'])),
        'jobTitle': json['jobTitle'],
        'exemptFromOvertime': json['exemptFromOvertime'],
        'workingHoursPerWeek': json['workingHoursPerWeek'],
        'leaveHoursUsedPerDay': json['leaveHoursUsedPerDay'],
        'employmentType': json['employmentType'],
        'canRequestLeave': json['canRequestLeave'],
        'joinDate': ShiftiePlainDateFromJSON(json['joinDate']),
        'probationDate': ShiftiePlainDateFromJSON(json['probationDate']),
        'terminationDate': ShiftiePlainDateFromJSON(json['terminationDate']),
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (json['updatedAt'] == null ? null : new Date(json['updatedAt'])),
        'user': json['user'] == null ? undefined : UserFromJSON(json['user']),
        'jobRoles': json['jobRoles'] == null ? undefined : ((json['jobRoles'] as Array<any>).map(JobRoleFromJSON)),
        'jobRoleIds': json['jobRoleIds'],
        'accessRoles': json['accessRoles'] == null ? undefined : ((json['accessRoles'] as Array<any>).map(AccessRoleFromJSON)),
        'accessRoleIds': json['accessRoleIds'],
        'employeeAttributes': json['employeeAttributes'] == null ? undefined : ((json['employeeAttributes'] as Array<any>).map(EmployeeAttributeFromJSON)),
        'employeeAttributeIds': json['employeeAttributeIds'],
        'photo': json['photo'] == null ? undefined : UploadFromJSON(json['photo']),
        'company': json['company'] == null ? undefined : CompanyFromJSON(json['company']),
        'employeeGroup': json['employeeGroup'] == null ? undefined : EmployeeGroupFromJSON(json['employeeGroup']),
        'locations': json['locations'] == null ? undefined : ((json['locations'] as Array<any>).map(LocationFromJSON)),
        'locationIds': json['locationIds'],
        'schedules': json['schedules'] == null ? undefined : ((json['schedules'] as Array<any>).map(ScheduleFromJSON)),
        'scheduleIds': json['scheduleIds'],
        'notifications': json['notifications'] == null ? undefined : ((json['notifications'] as Array<any>).map(NotificationFromJSON)),
        'employeesJobRoles': json['employeesJobRoles'] == null ? undefined : ((json['employeesJobRoles'] as Array<any>).map(EmployeesJobRoleFromJSON)),
        'employeeOnboarding': json['employeeOnboarding'] == null ? undefined : EmployeeOnboardingFromJSON(json['employeeOnboarding']),
        'employeePersonalDetail': json['employeePersonalDetail'] == null ? undefined : EmployeePersonalDetailsFromJSON(json['employeePersonalDetail']),
        'workPattern': json['workPattern'] == null ? undefined : WorkPatternFromJSON(json['workPattern']),
        'archivedAt': (json['archivedAt'] == null ? null : new Date(json['archivedAt'])),
        'archivedById': json['archivedById'],
    };
}

  export function EmployeeToJSON(json: any): Employee {
      return EmployeeToJSONTyped(json, false);
  }

  export function EmployeeToJSONTyped(value?: Employee | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'userId': value['userId'],
        'firstName': value['firstName'],
        'lastName': value['lastName'],
        'reference': value['reference'],
        'status': value['status'],
        'lineManagerId': value['lineManagerId'],
        'leaveApproverId': value['leaveApproverId'],
        'workPatternId': value['workPatternId'],
        'employeeGroupId': value['employeeGroupId'],
        'defaultJobRoleId': value['defaultJobRoleId'],
        'leavePolicyId': value['leavePolicyId'],
        'showContactDetails': value['showContactDetails'],
        'contactEmail': value['contactEmail'],
        'phoneNumber': value['phoneNumber'],
        'phoneNumberVerifiedAt': (value['phoneNumberVerifiedAt'] == null ? null : (value['phoneNumberVerifiedAt'] as any).toISOString()),
        'jobTitle': value['jobTitle'],
        'exemptFromOvertime': value['exemptFromOvertime'],
        'workingHoursPerWeek': value['workingHoursPerWeek'],
        'leaveHoursUsedPerDay': value['leaveHoursUsedPerDay'],
        'employmentType': value['employmentType'],
        'canRequestLeave': value['canRequestLeave'],
        'joinDate': ShiftiePlainDateToJSON(value['joinDate']),
        'probationDate': ShiftiePlainDateToJSON(value['probationDate']),
        'terminationDate': ShiftiePlainDateToJSON(value['terminationDate']),
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': (value['updatedAt'] == null ? null : (value['updatedAt'] as any).toISOString()),
        'user': UserToJSON(value['user']),
        'jobRoles': value['jobRoles'] == null ? undefined : ((value['jobRoles'] as Array<any>).map(JobRoleToJSON)),
        'jobRoleIds': value['jobRoleIds'],
        'accessRoles': value['accessRoles'] == null ? undefined : ((value['accessRoles'] as Array<any>).map(AccessRoleToJSON)),
        'accessRoleIds': value['accessRoleIds'],
        'employeeAttributes': value['employeeAttributes'] == null ? undefined : ((value['employeeAttributes'] as Array<any>).map(EmployeeAttributeToJSON)),
        'employeeAttributeIds': value['employeeAttributeIds'],
        'photo': UploadToJSON(value['photo']),
        'company': CompanyToJSON(value['company']),
        'employeeGroup': EmployeeGroupToJSON(value['employeeGroup']),
        'locations': value['locations'] == null ? undefined : ((value['locations'] as Array<any>).map(LocationToJSON)),
        'locationIds': value['locationIds'],
        'schedules': value['schedules'] == null ? undefined : ((value['schedules'] as Array<any>).map(ScheduleToJSON)),
        'scheduleIds': value['scheduleIds'],
        'notifications': value['notifications'] == null ? undefined : ((value['notifications'] as Array<any>).map(NotificationToJSON)),
        'employeesJobRoles': value['employeesJobRoles'] == null ? undefined : ((value['employeesJobRoles'] as Array<any>).map(EmployeesJobRoleToJSON)),
        'employeeOnboarding': EmployeeOnboardingToJSON(value['employeeOnboarding']),
        'employeePersonalDetail': EmployeePersonalDetailsToJSON(value['employeePersonalDetail']),
        'workPattern': WorkPatternToJSON(value['workPattern']),
        'archivedAt': (value['archivedAt'] == null ? null : (value['archivedAt'] as any).toISOString()),
        'archivedById': value['archivedById'],
    };
}

