/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BulkDeleteNotificationsData
 */
export interface BulkDeleteNotificationsData {
    /**
     * The ids of models to delete
     * @type {Array<string>}
     * @memberof BulkDeleteNotificationsData
     */
    ids: Array<string>;
}

/**
 * Check if a given object implements the BulkDeleteNotificationsData interface.
 */
export function instanceOfBulkDeleteNotificationsData(value: object): value is BulkDeleteNotificationsData {
    if (!('ids' in value) || value['ids'] === undefined) return false;
    return true;
}

export function BulkDeleteNotificationsDataFromJSON(json: any): BulkDeleteNotificationsData {
    return BulkDeleteNotificationsDataFromJSONTyped(json, false);
}

export function BulkDeleteNotificationsDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkDeleteNotificationsData {
    if (json == null) {
        return json;
    }
    return {
        
        'ids': json['ids'],
    };
}

  export function BulkDeleteNotificationsDataToJSON(json: any): BulkDeleteNotificationsData {
      return BulkDeleteNotificationsDataToJSONTyped(json, false);
  }

  export function BulkDeleteNotificationsDataToJSONTyped(value?: BulkDeleteNotificationsData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'ids': value['ids'],
    };
}

