/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BulkUpdateTimesheetEntriesData,
  ConfirmTimesheetEntryData,
  ConfirmTimesheetEntryResponse,
  CreateTimesheetEntryData,
  CreateTimesheetEntryResponse,
  ListTimesheetEntriesResponse,
  ListTimesheetEntryExportsResponse,
  SearchData,
  SearchTimesheetEntriesResponse,
  ShowTimesheetEntryResponse,
  UpdateTimesheetEntryData,
  UpdateTimesheetEntryResponse,
} from '../models/index';
import {
    BulkUpdateTimesheetEntriesDataFromJSON,
    BulkUpdateTimesheetEntriesDataToJSON,
    ConfirmTimesheetEntryDataFromJSON,
    ConfirmTimesheetEntryDataToJSON,
    ConfirmTimesheetEntryResponseFromJSON,
    ConfirmTimesheetEntryResponseToJSON,
    CreateTimesheetEntryDataFromJSON,
    CreateTimesheetEntryDataToJSON,
    CreateTimesheetEntryResponseFromJSON,
    CreateTimesheetEntryResponseToJSON,
    ListTimesheetEntriesResponseFromJSON,
    ListTimesheetEntriesResponseToJSON,
    ListTimesheetEntryExportsResponseFromJSON,
    ListTimesheetEntryExportsResponseToJSON,
    SearchDataFromJSON,
    SearchDataToJSON,
    SearchTimesheetEntriesResponseFromJSON,
    SearchTimesheetEntriesResponseToJSON,
    ShowTimesheetEntryResponseFromJSON,
    ShowTimesheetEntryResponseToJSON,
    UpdateTimesheetEntryDataFromJSON,
    UpdateTimesheetEntryDataToJSON,
    UpdateTimesheetEntryResponseFromJSON,
    UpdateTimesheetEntryResponseToJSON,
} from '../models/index';

export interface BulkUpdateTimesheetEntriesRequest {
    bulkUpdateTimesheetEntriesData?: BulkUpdateTimesheetEntriesData;
}

export interface ConfirmTimesheetEntryRequest {
    id: number;
    confirmTimesheetEntryData?: ConfirmTimesheetEntryData;
}

export interface CreateTimesheetEntryRequest {
    _with?: Array<string>;
    createTimesheetEntryData?: CreateTimesheetEntryData;
}

export interface DeleteTimesheetEntryRequest {
    id: number;
}

export interface ListTimesheetEntriesRequest {
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    where?: { [key: string]: object; };
    _with?: Array<string>;
    q?: string;
}

export interface ListTimesheetEntryExportsRequest {
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    where?: { [key: string]: object; };
    _with?: Array<string>;
    q?: string;
}

export interface SearchTimesheetEntriesRequest {
    orderBy?: Array<string>;
    page?: number;
    perPage?: number;
    _with?: Array<string>;
    q?: string;
    searchData?: SearchData;
}

export interface ShowTimesheetEntryRequest {
    id: number;
    _with?: Array<string>;
}

export interface UpdateTimesheetEntryRequest {
    id: number;
    _with?: Array<string>;
    updateTimesheetEntryData?: UpdateTimesheetEntryData;
}

/**
 * 
 */
export class TimesheetApi extends runtime.BaseAPI {

    /**
     * Bulk Update Timesheet Entries
     */
    async bulkUpdateTimesheetEntriesRaw(requestParameters: BulkUpdateTimesheetEntriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.timesheet-entry.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/timesheet-entries/bulk`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: BulkUpdateTimesheetEntriesDataToJSON(requestParameters['bulkUpdateTimesheetEntriesData']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Bulk Update Timesheet Entries
     */
    async bulkUpdateTimesheetEntries(requestParameters: BulkUpdateTimesheetEntriesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.bulkUpdateTimesheetEntriesRaw(requestParameters, initOverrides);
    }

    /**
     * Confirm or un-confirm a timesheet entry.
     * Confirm Timesheet Entry
     */
    async confirmTimesheetEntryRaw(requestParameters: ConfirmTimesheetEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConfirmTimesheetEntryResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling confirmTimesheetEntry().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.timesheet-entry.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/timesheet-entries/confirm/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ConfirmTimesheetEntryDataToJSON(requestParameters['confirmTimesheetEntryData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConfirmTimesheetEntryResponseFromJSON(jsonValue));
    }

    /**
     * Confirm or un-confirm a timesheet entry.
     * Confirm Timesheet Entry
     */
    async confirmTimesheetEntry(requestParameters: ConfirmTimesheetEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConfirmTimesheetEntryResponse> {
        const response = await this.confirmTimesheetEntryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Create a timesheet entry.
     * Create Timesheet Entry
     */
    async createTimesheetEntryRaw(requestParameters: CreateTimesheetEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateTimesheetEntryResponse>> {
        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.timesheet-entry.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/timesheet-entries`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateTimesheetEntryDataToJSON(requestParameters['createTimesheetEntryData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateTimesheetEntryResponseFromJSON(jsonValue));
    }

    /**
     * Create a timesheet entry.
     * Create Timesheet Entry
     */
    async createTimesheetEntry(requestParameters: CreateTimesheetEntryRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateTimesheetEntryResponse> {
        const response = await this.createTimesheetEntryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a timesheet entry.
     * Delete Timesheet Entry
     */
    async deleteTimesheetEntryRaw(requestParameters: DeleteTimesheetEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteTimesheetEntry().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/timesheet-entries/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a timesheet entry.
     * Delete Timesheet Entry
     */
    async deleteTimesheetEntry(requestParameters: DeleteTimesheetEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteTimesheetEntryRaw(requestParameters, initOverrides);
    }

    /**
     * List individual timesheet entries in the given period.
     * List Timesheet Entries
     */
    async listTimesheetEntriesRaw(requestParameters: ListTimesheetEntriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListTimesheetEntriesResponse>> {
        const queryParameters: any = {};

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.timesheet-entry.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/timesheet-entries`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListTimesheetEntriesResponseFromJSON(jsonValue));
    }

    /**
     * List individual timesheet entries in the given period.
     * List Timesheet Entries
     */
    async listTimesheetEntries(requestParameters: ListTimesheetEntriesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListTimesheetEntriesResponse> {
        const response = await this.listTimesheetEntriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * 
     * List Timesheet Entry Exports
     */
    async listTimesheetEntryExportsRaw(requestParameters: ListTimesheetEntryExportsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListTimesheetEntryExportsResponse>> {
        const queryParameters: any = {};

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['where'] != null) {
            queryParameters['where'] = requestParameters['where'];
        }

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.timesheet-entry.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/timesheet-exports`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListTimesheetEntryExportsResponseFromJSON(jsonValue));
    }

    /**
     * 
     * List Timesheet Entry Exports
     */
    async listTimesheetEntryExports(requestParameters: ListTimesheetEntryExportsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListTimesheetEntryExportsResponse> {
        const response = await this.listTimesheetEntryExportsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List individual timesheet entries in the given period. Filters are supplied in the body.
     * Search Timesheet Entries
     */
    async searchTimesheetEntriesRaw(requestParameters: SearchTimesheetEntriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchTimesheetEntriesResponse>> {
        const queryParameters: any = {};

        if (requestParameters['orderBy'] != null) {
            queryParameters['orderBy'] = requestParameters['orderBy'];
        }

        if (requestParameters['page'] != null) {
            queryParameters['page'] = requestParameters['page'];
        }

        if (requestParameters['perPage'] != null) {
            queryParameters['perPage'] = requestParameters['perPage'];
        }

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        if (requestParameters['q'] != null) {
            queryParameters['q'] = requestParameters['q'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.timesheet-entry.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/timesheet-entries/search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchDataToJSON(requestParameters['searchData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchTimesheetEntriesResponseFromJSON(jsonValue));
    }

    /**
     * List individual timesheet entries in the given period. Filters are supplied in the body.
     * Search Timesheet Entries
     */
    async searchTimesheetEntries(requestParameters: SearchTimesheetEntriesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchTimesheetEntriesResponse> {
        const response = await this.searchTimesheetEntriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Show Timesheet Entry
     */
    async showTimesheetEntryRaw(requestParameters: ShowTimesheetEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowTimesheetEntryResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling showTimesheetEntry().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.timesheet-entry.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/timesheet-entries/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowTimesheetEntryResponseFromJSON(jsonValue));
    }

    /**
     * Show Timesheet Entry
     */
    async showTimesheetEntry(requestParameters: ShowTimesheetEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowTimesheetEntryResponse> {
        const response = await this.showTimesheetEntryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a Timesheet Entry.
     * Update Timesheet Entry
     */
    async updateTimesheetEntryRaw(requestParameters: UpdateTimesheetEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UpdateTimesheetEntryResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateTimesheetEntry().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['_with'] != null) {
            queryParameters['with'] = requestParameters['_with'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.timesheet-entry.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/timesheet-entries/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateTimesheetEntryDataToJSON(requestParameters['updateTimesheetEntryData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateTimesheetEntryResponseFromJSON(jsonValue));
    }

    /**
     * Update a Timesheet Entry.
     * Update Timesheet Entry
     */
    async updateTimesheetEntry(requestParameters: UpdateTimesheetEntryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UpdateTimesheetEntryResponse> {
        const response = await this.updateTimesheetEntryRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
