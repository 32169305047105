// A list of approved and current icons as of November 2023
// We are using FontAwesome light

export const Icon = {
  AddressCard: 'fa-light fa-address-card',
  AlarmClock: 'fa-light fa-alarm-clock',
  AngleDown: 'fa-light fa-angle-down',
  AngleLeft: 'fa-light fa-angle-left',
  AngleRight: 'fa-light fa-angle-right',
  AngleUp: 'fa-light fa-angle-up',
  ArrowDown: 'fa-light fa-arrow-down',
  ArrowDownArrowUp: 'fa-light fa-arrow-down-arrow-up',
  ArrowDownShortWide: 'fa-light fa-arrow-down-short-wide', // unused at the moment
  ArrowDownToLine: 'fa-light fa-arrow-down-to-line', // unused at the moment
  ArrowDownWideShort: 'fa-light fa-arrow-down-wide-short',
  ArrowLeft: 'fa-light fa-arrow-left',
  ArrowLeftToLine: 'fa-light fa-arrow-left-to-line', // unused at the moment
  ArrowRight: 'fa-light fa-arrow-right',
  ArrowRightLong: 'fa-light fa-arrow-right-long',
  ArrowRightToLine: 'fa-light fa-arrow-right-to-line', // unused at the moment
  ArrowUp: 'fa-light fa-arrow-up',
  ArrowUpWideShort: 'fa-light fa-arrow-up-wide-short',
  ArrowsLeftRight: 'fa-light fa-arrows-left-right', // unused at the moment
  ArrowsRotate: 'fa-light fa-arrows-rotate',
  ArrowsUpDown: 'fa-light fa-arrows-up-down', // unused at the moment
  ArrowsUpDownLeftRight: 'fa-light fa-arrows-up-down-left-right', // unused at the moment
  BadgePercent: 'fa-light fa-badge-percent', // unused at the moment
  Ban: 'fa-light fa-ban',
  Bars: 'fa-light fa-bars',
  Bell: 'fa-light fa-bell',
  BellSlash: 'fa-light fa-bell-slash',
  Bolt: 'fa-light fa-bolt',
  BoltSolid: 'fa-solid fa-bolt',
  BookOpen: 'fa-light fa-book-open',
  BoxArchive: 'fa-light fa-box-archive',
  Branch: 'fa-light fa-code-branch',
  Briefcase: 'fa-light fa-briefcase',
  Browser: 'fa-light fa-browser',
  Bullhorn: 'fa-light fa-bullhorn',
  BurgerSoda: 'fa-light fa-burger-soda',
  CakeCandles: 'fa-light fa-cake-candles',
  Calculator: 'fa-light fa-calculator',
  Calendar: 'fa-light fa-calendar',
  CalendarCheck: 'fa-light fa-calendar-check',
  CalendarDay: 'fa-light fa-calendar-day',
  CalendarDays: 'fa-light fa-calendar-days',
  CalendarExclamation: 'fa-light fa-calendar-exclamation',
  CalendarMinus: 'fa-light fa-calendar-minus',
  CalendarPen: 'fa-light fa-calendar-pen',
  CalendarPlus: 'fa-light fa-calendar-plus',
  CalendarStar: 'fa-light fa-calendar-star',
  CalendarWeek: 'fa-light fa-calendar-week',
  CalendarXmark: 'fa-light fa-calendar-xmark',
  Camera: 'fa-light fa-camera',
  CaretDown: 'fa-light fa-caret-down',
  CaretRight: 'fa-light fa-caret-right',
  CaretUp: 'fa-light fa-caret-up',
  ChartColumn: 'fa-light fa-chart-column',
  ChartNetwork: 'fa-light fa-chart-network',
  ChartPie: 'fa-light fa-file-chart-pie',
  ChartSimple: 'fa-light fa-chart-simple',
  ChartHorizontal: 'fa-light fa-chart-simple-horizontal',
  Check: 'fa-light fa-check',
  ChevronDown: 'fa-light fa-chevron-down',
  ChevronLeft: 'fa-light fa-chevron-left',
  ChevronRight: 'fa-light fa-chevron-right',
  ChevronUp: 'fa-light fa-chevron-up',
  Circle: 'fa-light fa-circle',
  CircleArrowRight: 'fa-light fa-circle-arrow-right',
  CircleCheck: 'fa-light fa-circle-check',
  CircleCheckSolid: 'fa-solid fa-circle-check',
  CircleDot: 'fa-light fa-circle-dot',
  CircleExclamation: 'fa-light fa-circle-exclamation',
  CircleExclamationSolid: 'fa-solid fa-circle-exclamation',
  CircleInfo: 'fa-light fa-circle-info',
  CircleMinus: 'fa-light fa-circle-minus',
  CirclePlay: 'fa-light fa-circle-play', // unused at the moment
  CirclePlus: 'fa-light fa-circle-plus',
  CircleQuestion: 'fa-light fa-circle-question',
  CircleStop: 'fa-light fa-circle-stop',
  CircleXmark: 'fa-light fa-circle-xmark',
  CircleXmarkSolid: 'fa-solid fa-circle-xmark',
  ClipboardList: 'fa-light fa-clipboard-list',
  ClipboardListCheck: 'fa-light fa-clipboard-list-check',
  Clock: 'fa-light fa-clock',
  ClockRotateLeft: 'fa-light fa-clock-rotate-left',
  ClockSolid: 'fa-solid fa-clock',
  CloudHail: 'fa-light fa-cloud-hail', // unused at the moment
  Code: 'fa-light fa-code',
  Coins: 'fa-light fa-coins',
  Comment: 'fa-light fa-comment',
  CommentsDollar: 'fa-light fa-comments-dollar', // unused at the moment
  CommentSolid: 'fa-solid fa-comment',
  CookieBite: 'fa-light fa-cookie-bite',
  Copy: 'fa-light fa-copy',
  CreditCard: 'fa-light fa-credit-card',
  DoubleCheck: 'fa-light fa-check-double',
  Download: 'fa-light fa-down-to-line', // key with custom name
  EathEurope: 'fa-light fa-earth-europe',
  Ellipsis: 'fa-light fa-ellipsis',
  EllipsisVertical: 'fa-light fa-ellipsis-vertical',
  Envelope: 'fa-light fa-envelope',
  Exclamation: 'fa-light fa-exclamation',
  Expand: 'fa-light fa-up-right-and-down-left-from-center', // key with custom name
  ExternalLink: 'fa-light fa-arrow-up-right-from-square', // key with custom name
  Eye: 'fa-light fa-eye',
  EyeSlash: 'fa-light fa-eye-slash',
  FaceSmile: 'fa-light fa-face-smile', // unused at the moment
  File: 'fa-light fa-file',
  FileCSV: 'fa-light fa-file-csv',
  FileChartColumn: 'fa-light fa-file-chart-column',
  FileDownload: 'fa-light fa-file-arrow-down', // key with custom name
  FileExcel: 'fa-light fa-file-excel',
  FileExport: 'fa-light fa-file-export',
  FileImage: 'fa-light fa-file-image',
  FileImport: 'fa-light fa-file-import',
  FileInvoice: 'fa-light fa-file-invoice',
  FileLines: 'fa-light fa-file-lines',
  FilePDF: 'fa-light fa-file-pdf',
  FilePowerPoint: 'fa-light fa-file-powerpoint',
  FileWord: 'fa-light fa-file-word',
  Filter: 'fa-light fa-filter',
  FloppyDisk: 'fa-light fa-floppy-disk',
  Gauge: 'fa-light fa-gauge',
  Gear: 'fa-light fa-gear',
  Gift: 'fa-light fa-gift',
  Globe: 'fa-light fa-globe',
  Hand: 'fa-light fa-hand',
  Heart: 'fa-light fa-heart',
  Hourglass: 'fa-light fa-hourglass',
  House: 'fa-light fa-house',
  IdCard: 'fa-light fa-id-card',
  Inbox: 'fa-light fa-inbox',
  IndustryWindows: 'fa-light fa-industry-windows',
  Infinity: 'fa-light fa-infinity',
  Info: 'fa-light fa-info',
  Key: 'fa-light fa-key',
  LifeRing: 'fa-light fa-life-ring',
  LightBulbOn: 'fa-light fa-lightbulb-on',
  Link: 'fa-light fa-link',
  List: 'fa-light fa-list',
  ListCheck: 'fa-light fa-list-check',
  Loading: 'fa-light fa-spinner-third fa-spin',
  LocationArrow: 'fa-light fa-location-arrow',
  LocationDot: 'fa-light fa-location-dot',
  LocationPin: 'fa-light fa-location-pin',
  Lock: 'fa-light fa-lock',
  LockOpen: 'fa-light fa-lock-open',
  MagnifyingGlass: 'fa-light fa-magnifying-glass',
  Mailbox: 'fa-light fa-mailbox',
  Maximize: 'fa-light fa-maximize',
  Medal: 'fa-light fa-medal',
  Message: 'fa-light fa-message',
  MessageDots: 'fa-light fa-message-dots', // unused at the moment
  MessageLines: 'fa-light fa-message-lines',
  Messages: 'fa-light fa-messages',
  Minus: 'fa-light fa-minus',
  Mobile: 'fa-light fa-mobile',
  MonitorWaveform: 'fa-light fa-monitor-waveform',
  Moon: 'fa-light fa-moon',
  Mug: 'fa-light fa-mug',
  MugSaucer: 'fa-light fa-mug-saucer',
  Notes: 'fa-light fa-notes',
  NotesSolid: 'fa-solid fa-notes',
  PaperPlane: 'fa-light fa-paper-plane',
  Pause: 'fa-light fa-pause',
  Pen: 'fa-light fa-pen',
  PenToSquare: 'fa-light fa-pen-to-square',
  PhoneFlip: 'fa-light fa-phone-flip',
  PhoneRotary: 'fa-light fa-phone-rotary',
  Plane: 'fa-light fa-plane',
  Plus: 'fa-light fa-plus',
  PowerOff: 'fa-light fa-power-off',
  Print: 'fa-light fa-print',
  PuzzlePiece: 'fa-light fa-puzzle-piece',
  Question: 'fa-light fa-question',
  Repeat: 'fa-light fa-repeat',
  RotateLeft: 'fa-light fa-rotate-left',
  RotateRight: 'fa-light fa-rotate-right',
  ShareFromSquare: 'fa-light fa-share-from-square',
  ShieldCheck: 'fa-light fa-shield-check',
  Shuffle: 'fa-light fa-shuffle',
  Sliders: 'fa-light fa-sliders',
  Sort: 'fa-light fa-sort',
  SquarePen: 'fa-light fa-square-pen',
  Star: 'fa-light fa-star',
  StopWatch: 'fa-light fa-stopwatch',
  StopWatchSolid: 'fa-solid fa-stopwatch',
  Suitcase: 'fa-light fa-suitcase',
  TableCells: 'fa-light fa-table-cells',
  Tag: 'fa-light fa-tag',
  Tags: 'fa-light fa-tags',
  ThumbsUp: 'fa-light fa-thumbs-up',
  Thumbtack: 'fa-light fa-thumbtack',
  Ticket: 'fa-light fa-ticket',
  TrashCan: 'fa-light fa-trash-can',
  TriangleExclamation: 'fa-light fa-triangle-exclamation',
  TrophyStar: 'fa-light fa-trophy-star',
  TruckMedical: 'fa-light fa-truck-medical',
  Umbrella: 'fa-light fa-umbrella',
  Unlock: 'fa-light fa-unlock',
  Upload: 'fa-light fa-cloud-arrow-up', // key with custom name
  User: 'fa-light fa-user',
  UserCheck: 'fa-light fa-user-check',
  UserClock: 'fa-light fa-user-clock',
  UserLock: 'fa-light fa-user-lock',
  UserPlus: 'fa-light fa-user-plus',
  UserSlash: 'fa-light fa-user-slash',
  UserTag: 'fa-light fa-user-tag',
  UserTie: 'fa-light fa-user-tie',
  UserXmark: 'fa-light fa-user-xmark',
  Users: 'fa-light fa-users',
  Wallet: 'fa-light fa-wallet',
  Window: 'fa-light fa-window',
  WindowMaximise: 'fa-light fa-window-maximize',
  Wrench: 'fa-light fa-wrench',
  Xmark: 'fa-light fa-xmark',
} as const;

export type IconType = typeof Icon[keyof typeof Icon];

export const BrandIcon = {
  Chrome: 'fa-brands fa-chrome',
  Edge: 'fa-brands fa-edge',
  FacebookF: 'fa-brands fa-facebook-f',
  Firefox: 'fa-brands fa-firefox',
  IE: 'fa-brands fa-internet-explorer',
  LinkedIn: 'fa-brands fa-linkedin-in',
  Safari: 'fa-brands fa-safari',
  Twitter: 'fa-brands fa-twitter',
} as const;

export type BrandIconType = typeof BrandIcon[keyof typeof BrandIcon];
