/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateLeaveTypeData
 */
export interface CreateLeaveTypeData {
    /**
     * 
     * @type {number}
     * @memberof CreateLeaveTypeData
     */
    deductionLeaveTypeId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateLeaveTypeData
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof CreateLeaveTypeData
     */
    colour: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateLeaveTypeData
     */
    paid: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateLeaveTypeData
     */
    payCode: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateLeaveTypeData
     */
    displayIcon?: CreateLeaveTypeDataDisplayIconEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateLeaveTypeData
     */
    leaveAllowanceType: CreateLeaveTypeDataLeaveAllowanceTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof CreateLeaveTypeData
     */
    leaveAllowanceUnit: CreateLeaveTypeDataLeaveAllowanceUnitEnum;
    /**
     * Leave allowance for this leave type when using the 'Fixed' allowance type.
     * @type {number}
     * @memberof CreateLeaveTypeData
     */
    leaveAllowance?: number | null;
    /**
     * What percentage of worked hours an employee accrues for this leave type. A rate of 100 means leave is accrued at the same rate as hours worked.
     * @type {number}
     * @memberof CreateLeaveTypeData
     */
    accrualRatePerHour?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CreateLeaveTypeData
     */
    accrualLimitDaysPerPeriod?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateLeaveTypeData
     */
    accrualAllowedOnOvertimeHours?: boolean;
}

/**
* @export
* @enum {string}
*/
export enum CreateLeaveTypeDataDisplayIconEnum {
    SolidFaBaby = 'fa-solid fa-baby',
    SolidFaBabyCarriage = 'fa-solid fa-baby-carriage',
    SolidFaBasketShoppingSimple = 'fa-solid fa-basket-shopping-simple',
    SolidFaBed = 'fa-solid fa-bed',
    SolidFaBriefcase = 'fa-solid fa-briefcase',
    SolidFaCakeCandles = 'fa-solid fa-cake-candles',
    SolidFaCar = 'fa-solid fa-car',
    SolidFaCarBus = 'fa-solid fa-car-bus',
    SolidFaClock = 'fa-solid fa-clock',
    SolidFaEarthEurope = 'fa-solid fa-earth-europe',
    SolidFaFaceFrownSlight = 'fa-solid fa-face-frown-slight',
    SolidFaFaceThermometer = 'fa-solid fa-face-thermometer',
    SharpFaSolidFaGavel = 'fa-sharp fa-solid fa-gavel',
    SolidFaGift = 'fa-solid fa-gift',
    SolidFaGraduationCap = 'fa-solid fa-graduation-cap',
    SolidFaHandsPraying = 'fa-solid fa-hands-praying',
    SolidFaHandHoldingHeart = 'fa-solid fa-hand-holding-heart',
    SolidFaHeadSideGear = 'fa-solid fa-head-side-gear',
    SolidFaHouse = 'fa-solid fa-house',
    SolidFaPaw = 'fa-solid fa-paw',
    SharpFaSolidFaPersonBreastfeeding = 'fa-sharp fa-solid fa-person-breastfeeding',
    SharpFaSolidFaPersonPregnant = 'fa-sharp fa-solid fa-person-pregnant',
    SolidFaPlane = 'fa-solid fa-plane',
    SolidFaSnowflake = 'fa-solid fa-snowflake',
    SolidFaSpa = 'fa-solid fa-spa',
    SolidFaSquarePlus = 'fa-solid fa-square-plus',
    SolidFaSuitcaseRolling = 'fa-solid fa-suitcase-rolling',
    SolidFaUmbrellaBeach = 'fa-solid fa-umbrella-beach',
    SolidFaUsers = 'fa-solid fa-users',
    SolidFaVirus = 'fa-solid fa-virus'
}
/**
* @export
* @enum {string}
*/
export enum CreateLeaveTypeDataLeaveAllowanceTypeEnum {
    Accrued = 'Accrued',
    DeductsFromAnother = 'Deducts From Another',
    Fixed = 'Fixed',
    Unlimited = 'Unlimited'
}
/**
* @export
* @enum {string}
*/
export enum CreateLeaveTypeDataLeaveAllowanceUnitEnum {
    Days = 'Days',
    Hours = 'Hours'
}


/**
 * Check if a given object implements the CreateLeaveTypeData interface.
 */
export function instanceOfCreateLeaveTypeData(value: object): value is CreateLeaveTypeData {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('colour' in value) || value['colour'] === undefined) return false;
    if (!('paid' in value) || value['paid'] === undefined) return false;
    if (!('payCode' in value) || value['payCode'] === undefined) return false;
    if (!('leaveAllowanceType' in value) || value['leaveAllowanceType'] === undefined) return false;
    if (!('leaveAllowanceUnit' in value) || value['leaveAllowanceUnit'] === undefined) return false;
    return true;
}

export function CreateLeaveTypeDataFromJSON(json: any): CreateLeaveTypeData {
    return CreateLeaveTypeDataFromJSONTyped(json, false);
}

export function CreateLeaveTypeDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateLeaveTypeData {
    if (json == null) {
        return json;
    }
    return {
        
        'deductionLeaveTypeId': json['deductionLeaveTypeId'] == null ? undefined : json['deductionLeaveTypeId'],
        'name': json['name'],
        'colour': json['colour'],
        'paid': json['paid'],
        'payCode': json['payCode'],
        'displayIcon': json['displayIcon'] == null ? undefined : json['displayIcon'],
        'leaveAllowanceType': json['leaveAllowanceType'],
        'leaveAllowanceUnit': json['leaveAllowanceUnit'],
        'leaveAllowance': json['leaveAllowance'] == null ? undefined : json['leaveAllowance'],
        'accrualRatePerHour': json['accrualRatePerHour'] == null ? undefined : json['accrualRatePerHour'],
        'accrualLimitDaysPerPeriod': json['accrualLimitDaysPerPeriod'] == null ? undefined : json['accrualLimitDaysPerPeriod'],
        'accrualAllowedOnOvertimeHours': json['accrualAllowedOnOvertimeHours'] == null ? undefined : json['accrualAllowedOnOvertimeHours'],
    };
}

  export function CreateLeaveTypeDataToJSON(json: any): CreateLeaveTypeData {
      return CreateLeaveTypeDataToJSONTyped(json, false);
  }

  export function CreateLeaveTypeDataToJSONTyped(value?: CreateLeaveTypeData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'deductionLeaveTypeId': value['deductionLeaveTypeId'],
        'name': value['name'],
        'colour': value['colour'],
        'paid': value['paid'],
        'payCode': value['payCode'],
        'displayIcon': value['displayIcon'],
        'leaveAllowanceType': value['leaveAllowanceType'],
        'leaveAllowanceUnit': value['leaveAllowanceUnit'],
        'leaveAllowance': value['leaveAllowance'],
        'accrualRatePerHour': value['accrualRatePerHour'],
        'accrualLimitDaysPerPeriod': value['accrualLimitDaysPerPeriod'],
        'accrualAllowedOnOvertimeHours': value['accrualAllowedOnOvertimeHours'],
    };
}

