/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ClockInData
 */
export interface ClockInData {
    /**
     * Which employee should be clocked in. Leave blank to clock yourself in.
     * @type {number}
     * @memberof ClockInData
     */
    employeeId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClockInData
     */
    shiftId?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ClockInData
     */
    locationId?: number;
    /**
     * Any notes that the employee wants to add to the timesheet.
     * @type {string}
     * @memberof ClockInData
     */
    notes?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ClockInData
     */
    clockingLatitude?: number;
    /**
     * 
     * @type {number}
     * @memberof ClockInData
     */
    clockingLongitude?: number;
}

/**
 * Check if a given object implements the ClockInData interface.
 */
export function instanceOfClockInData(value: object): value is ClockInData {
    return true;
}

export function ClockInDataFromJSON(json: any): ClockInData {
    return ClockInDataFromJSONTyped(json, false);
}

export function ClockInDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ClockInData {
    if (json == null) {
        return json;
    }
    return {
        
        'employeeId': json['employeeId'] == null ? undefined : json['employeeId'],
        'shiftId': json['shiftId'] == null ? undefined : json['shiftId'],
        'locationId': json['locationId'] == null ? undefined : json['locationId'],
        'notes': json['notes'] == null ? undefined : json['notes'],
        'clockingLatitude': json['clockingLatitude'] == null ? undefined : json['clockingLatitude'],
        'clockingLongitude': json['clockingLongitude'] == null ? undefined : json['clockingLongitude'],
    };
}

  export function ClockInDataToJSON(json: any): ClockInData {
      return ClockInDataToJSONTyped(json, false);
  }

  export function ClockInDataToJSONTyped(value?: ClockInData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'employeeId': value['employeeId'],
        'shiftId': value['shiftId'],
        'locationId': value['locationId'],
        'notes': value['notes'],
        'clockingLatitude': value['clockingLatitude'],
        'clockingLongitude': value['clockingLongitude'],
    };
}

